import * as types from '../actions/Types';
import { resourcesTypes } from '../actions/Resources';

const DEFAULT_STATE = {
  redirect: null,
  isLoading: false,
  loadingCount: 0,
  selectedStudy: null,
  showOpenedMenu: false,
  printing: false,
  skyanAccess: { status: 500 },
  measureScale: { measureSymbol: '', measureLabel: 'None', measureId: 1 },
  okta: { registering: {} },
  maintenanceStatus: '',
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_SELECTED_STUDY: {
      return {
        ...state,
        selectedStudy: action.selectedStudy,
      };
    }
    case types.SET_TO_EXPORT_STUDY: {
      return {
        ...state,
        toExportStudy: action.toExportStudy,
      };
    }
    case types.SET_SELECTED_ALLOCATED_BY_VALUE: {
      return {
        ...state,
        selectedAllocatedByValue: action.selectedAllocatedByValue,
      };
    }
    case types.UPDATE_STUDY_DATA: {
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          study: action.study,
        },
      };
    }
    case types.STORE_ECONOMIC_SCENARIOS: {
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          study: {
            ...state.selectedStudy.study,
            ...action,
          },
        },
      };
    }
    case types.STORE_AUM_AND_RETURN: {
      const stateCopy = { ...state };
      stateCopy.selectedStudy.study = {
        ...stateCopy.selectedStudy.study,
        ...action.graphData,
      };
      return {
        ...state,
        ...stateCopy,
      };
    }
    case types.IS_STUDY_VALID: {
      return {
        ...state,
        studyIsValid: action.studyIsValid,
      };
    }
    case resourcesTypes.RESOURCES_STUDIES_DATA:
    case types.STUDY_RESET_DATA: {
      return {
        ...state,
        selectedStudy: null,
      };
    }
    case types.MUST_REVERT: {
      const deepClone = JSON.parse(JSON.stringify(state.originalVersion));
      return {
        ...state,
        selectedStudy: deepClone,
      };
    }
    case types.CASH_FLOWS_DATA: {
      const { cashFlows } = action;
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          cashFlows,
        },
      };
    }
    case types.CALCULATED_CASH_FLOWS: {
      const { calculatedCashFlows } = action;
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          calculatedCashFlows,
        },
      };
    }
    case types.IS_RELATIVE_TO_BUDGET: {
      return {
        ...state,
        isRelativeToBudget: action.isRelativeToBudget,
      };
    }
    case types.DISPLAY_SINGLE_COLUMN_LAYOUT: {
      return {
        ...state,
        displaySingleColumnLayout: action.displaySingleColumnLayout,
      };
    }
    case types.SET_TOTAL_MARKET_VALUE: {
      return {
        ...state,
        totalMarketValue: action.totalMarketValue,
      };
    }
    case types.EMPTY_ASSET_POOL_DESCRIPTION: {
      return {
        ...state,
        emptyAssetPoolDescription: action.emptyAssetPoolDescription,
      };
    }
    case types.EMPTY_ASSET_POOL_RISK_PROXY: {
      return {
        ...state,
        emptyAssetPoolRiskProxy: action.emptyAssetPoolRiskProxy,
      };
    }
    case types.SET_SELECTED_CLIENT: {
      return {
        ...state,
        selectedClient: action.selectedClient,
      };
    }
    case types.TOGGLE_SETTINGS_MENU: {
      return {
        ...state,
        showOpenedMenu: !state.showOpenedMenu,
      };
    }
    case types.SELECTED_ASSUMPTION: {
      return {
        ...state,
        selectedAssumption: action.assumption,
        selectedStudy: {
          ...state.selectedStudy,
          study: {
            ...state.selectedStudy.study,
            assumptionId: action.assumption,
          },
        },
      };
    }
    case types.SELECTED_MEASURE: {
      return {
        ...state,
        measureScale: action.measureScale,
        selectedStudy: {
          ...state.selectedStudy,
          study: {
            ...state.selectedStudy.study,
            measureScaleId: action.measureScale.measureId,
          },
        },
      };
    }
    case types.ORIGINAL_VERSION: {
      const deepClone = JSON.parse(JSON.stringify(state.selectedStudy));
      return {
        ...state,
        originalVersion: deepClone,
      };
    }
    case types.SET_CREDENTIALS: {
      const { okta } = action;
      const data = {
        username: okta?.idToken?.claims?.name,
        email: okta?.idToken?.claims?.email,
        token: okta?.accessToken?.accessToken,
      };
      return {
        ...state,
        okta: data,
      };
    }
    case types.RESET_CREDENTIALS: {
      return {
        ...state,
        okta: null,
      };
    }
    case types.PRINTING: {
      return {
        ...state,
        printing: action.printing,
      };
    }
    case types.SKYAN_ACCESS: {
      return {
        ...state,
        skyanAccess: action.skyanAccess,
      };
    }
    case types.LOADING: {
      const loadingCount = state.loadingCount + action.change;
      const isLoading = loadingCount > 0;
      return {
        ...state,
        loadingCount,
        isLoading,
      };
    }
    case types.REDIRECT: {
      return {
        ...state,
        redirect: action.to,
      };
    }
    case types.UPDATE_SELECTED_MAINTENANCE_FIELD: {
      return {
        ...state,
        maintenanceSelectedField: action.maintenanceSelectedField,
      };
    }
    case types.MAINTENANCE_STATUS: {
      return {
        ...state,
        maintenanceStatus: action.maintenanceStatus,
      };
    }
    case types.UPDATE_OTHER_FINANCIAL_PROJECTIONS_DATA: {
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          otherFinancialProjections: {
            ...state.selectedStudy.otherFinancialProjections,
            ...action.otherFinancialProjections,
          },
        },
      };
    }
    case types.SET_FINANCIAL_METRICS_DATA: {
      return {
        ...state,
        selectedStudy: {
          ...state.selectedStudy,
          financialMetrics: {
            ...action.financialMetrics,
          },
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};
