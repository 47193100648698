import styled from 'styled-components';

export const NoAccessPage = styled.div`
  display: flex;
  padding-top: 5em;
  font-size: 1.5em;
  text-align: center;
  justify-content: center;
  height: 200px;
`;
