import * as types from '../actions/Types';

export const DEFAULT_REGISTERING = {
  user: {
    email: '',
    firstName: '',
    lastName: '',
  },
  success: false,
  emailNoClientFound: false,
  emailAlreadyRegistered: false,
  wrongCaptcha: false,
  error: false,
};

export const DEFAULT_STATE = {
  settings: {
    issuer: '',
    clientId: '',
    redirectUri: `${window.location.origin}/implicit/callback`,
    baseUrl: '',
  },
  isEmployee: false,
  isClient: false,
  isRolesLoaded: false,
  user: null,
  roles: [],
  accessToken: null,
  contactId: null,
  registering: DEFAULT_REGISTERING,
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.REGISTER_USER_SUCCESS:
    case types.REGISTER_USER_FAILED: {
      return {
        ...state,
        registering: action.registering,
      };
    }
    default: {
      return state;
    }
  }
};
