import React, { useEffect, useState } from 'react';
import Main from '../Main';
import Constants from '../../services/Constants';
import Authenticated from '../Authenticated';

import { Route, Switch, Redirect, useHistory, BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from '../Login/config';
import Login from '../Login';
import { redirect, dispatchUserData, isStudyModified, toggleSettingsMenu } from '../../actions';
import { checkMaintenanceStatus } from '../../actions/Resources';
import Unauthorized from '../Unauthorized';
import PageNotFound from '../PageNotFound';
import Register from '../Login/Register';
import MaintenanceScreen from '../MaintenanceScreen';

const oktaAuth = new OktaAuth(config.oidc);

const _AppRouter = (properties) => {
  const history = useHistory();
  const [maintenance] = useState();

  useEffect(() => {
    properties.props.checkMaintenanceStatus();
  }, [maintenance, properties.props.maintenanceStatus]);

  useEffect(() => {
    maintenaceRedirect();
  }, [properties.props.maintenanceStatus, properties.props.okta?.token, maintenance]);

  history.block((location, action) => {
    const tokens = oktaAuth?.tokenManager.getTokensSync();
    if (
      action === 'POP' &&
      history.location.pathname.includes('/study/') &&
      !location.pathname.includes('/study/') &&
      properties.props.isStudyModified() &&
      oktaAuth?.isAuthenticated() &&
      !oktaAuth.tokenManager.hasExpired(tokens.accessToken) &&
      !window.confirm(
        'There are unsaved changes. Are you sure you want to leave this page?\nClick Cancel to stay and finish your work. Click OK to discard your changes.'
      )
    ) {
      history.goForward();
      return false;
    }

    if (history.location.pathname === location.pathname) {
      history.goForward();
      return false;
    }

    return true;
  });

  const withMain = (selectedView, viewParams = {}) => {
    const inNoAccessPage = selectedView === Constants.NO_SKYAN_ACCESS;
    const inTermsAndConditionsPage = selectedView === Constants.TERMS_AND_CONDITIONS_VIEW;

    if (!inTermsAndConditionsPage && !inNoAccessPage && properties.props.acceptedTerms === false) {
      return withMain(Constants.TERMS_AND_CONDITIONS_VIEW);
    }
    if (
      !properties.props.okta?.email?.includes('@nepc') &&
      !inNoAccessPage &&
      !inTermsAndConditionsPage &&
      properties.props.skyanAccess.status === 406
    ) {
      return withMain(Constants.NO_SKYAN_ACCESS);
    }
    return <Main selectedView={selectedView} viewParams={viewParams} />;
  };

  const handleStudy = (match) => {
    const vp = {
      studyId: match.params.studyId,
      clientGuid: match.params.clientGuid,
    };
    return withMain(Constants.STUDY_VIEW, vp);
  };

  const handleCardsMenu = () => {
    return withMain(Constants.CARDS_VIEW);
  };

  const handleSettingsMenu = () => {
    return withMain(Constants.SETTINGS_MENU_VIEW);
  };

  const handleTerms = () => {
    return withMain(Constants.TERMS_AND_CONDITIONS_VIEW);
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    if (!location.pathname.includes('/maintenance') && !maintenance) {
      return properties.props.redirect('/login');
    }
  };

  const maintenaceRedirect = () => {
    if (
      properties.props.maintenanceStatus &&
      !location.pathname.includes('/maintenance') &&
      !(location.pathname.includes('/settings') || location.pathname.includes('/login'))
    ) {
      return properties.props.redirect('/maintenance');
    }
  };

  const handleLogout = async () => {
    oktaAuth.signOut();
  };

  const onLogin = () => {
    const { maintenanceStatus } = properties.props;
    return <Login maintenanceMode={maintenanceStatus} />;
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route exact path="/login/callback" render={() => <LoginCallback />} />
        <Route exact path="/login" render={() => onLogin()} />
        <Route exact path="/logout" render={() => handleLogout()} />
        <Route exact path="/register" render={() => <Register />} />
        <Route exact path="/unauthorized" render={() => <Unauthorized />} />
        <Route
          exact
          path="/maintenance"
          render={() => <MaintenanceScreen maintenanceMode={properties.props.maintenanceStatus} />}
        />
        <SecureRoute exact path="/settings" render={() => handleSettingsMenu()} />
        <SecureRoute exact path="/study/:studyId/:clientGuid" render={({ match }) => handleStudy(match)} />
        <SecureRoute exact path="/study/:studyId" render={({ match }) => handleStudy(match)} />
        <SecureRoute exact path="/terms" render={() => handleTerms()} />
        <SecureRoute exact path="/" render={() => handleCardsMenu()} />
        <Route path="*" render={PageNotFound} />
      </Switch>
      <Authenticated />
    </Security>
  );
};

_AppRouter.propTypes = {
  originalVersion: PropTypes.object,
  selectedStudy: PropTypes.object,
  okta: PropTypes.object,
  toggleSettingsMenu: PropTypes.func,
  maintenanceStatus: PropTypes.string,
  checkMaintenanceStatus: PropTypes.func,
};

const redirectPage = (props) => {
  if (props.redirectTo) {
    props.redirect(null);
    return <Redirect push={true} to={props.redirectTo} />;
  }
  return null;
};

const AppRouter = (props) => {
  return <Router>{redirectPage(props) || <_AppRouter props={props} />}</Router>;
};

AppRouter.propTypes = {
  maintenanceStatus: PropTypes.bool.isRequired,
  acceptedTerms: PropTypes.bool,
  dispatchUserData: PropTypes.func,
  okta: PropTypes.object,
  originalVersion: PropTypes.object,
  redirect: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  selectedStudy: PropTypes.object,
  skyanAccess: PropTypes.object,
  isStudyModified: PropTypes.func,
  okta: PropTypes.object,
  checkMaintenanceStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    acceptedTerms: state.terms.accepted,
    okta: state.ui.okta,
    originalVersion: state.ui.originalVersion,
    redirectTo: state.ui.redirect,
    selectedStudy: state.ui.selectedStudy,
    skyanAccess: state.ui.skyanAccess,
    maintenanceStatus: state.resources.maintenance_status.data || false,
  };
};

export default connect(mapStateToProps, {
  dispatchUserData,
  redirect,
  isStudyModified,
  toggleSettingsMenu,
  checkMaintenanceStatus,
})(AppRouter);
