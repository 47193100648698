import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const SquareFlag = ({ color }) => {
  return <StyledSquareFlag className="square-flag" color={color}></StyledSquareFlag>;
};

SquareFlag.propTypes = {
  color: PropTypes.string.isRequired,
};

const StyledSquareFlag = styled.div`
  width: 15px;
  height: 15px;
  margin: 0.5rem 0px;
  background: ${(props) => props.color};
`;

export default SquareFlag;
