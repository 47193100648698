import {
  loadAssetAllocationResources,
  loadRiskProxiesResources,
  loadAssumptionsResources,
  loadClientResources,
} from './Resources';
import denodoService from '../services/denodoService';
import { withLoading } from './CommonActions';
import * as Types from './Types';

export const loadRiskProxies = withLoading((clientGuid) => () => loadRiskProxiesResources(clientGuid));

export const loadAssetAllocation = withLoading(
  (mixIDs, allocatedBy) => () => loadAssetAllocationResources(mixIDs, allocatedBy)
);

export const loadAssumptions = withLoading(() => () => loadAssumptionsResources());

export const loadClients = withLoading(() => (dispatch, getState) => {
  const {
    ui: { okta },
  } = getState();
  return loadClientResources(okta?.email);
});

export const loadAuthorization = withLoading(() => {
  return (dispatch, getState) => {
    const {
      ui: { okta },
    } = getState();
    return denodoService()
      .getSkyanAuthorization(okta?.email)
      .then((response) => {
        return dispatch({ type: Types.SKYAN_ACCESS, skyanAccess: response });
      })
      .catch((response) => {
        return dispatch({ type: Types.SKYAN_ACCESS, skyanAccess: response.response });
      });
  };
});
