const BUDGET_INDEX = 0;

export const FORECASTED_AUM_CHILD_IDX = 101;
export const DCOH_CHILD_IDX = 102;
export const INV_GAIN_LOSS_CHILD_IDX = 103;
export const INV_RETURN_CHILD_IDX = 104;

// #region Economic Scenarios Calculations
export const _calcForecastedAUM = (previousYearForecastedAUM, currentYearRiskReturnRate, partialCashFlowSum) =>
  previousYearForecastedAUM * (1 + currentYearRiskReturnRate) + partialCashFlowSum;

export const _getForecastedAUM = (totalMarketValue, scenario, partialCashFlowsSum, childIdx) => ({
  description: 'AUM',
  key: childIdx,
  measure: '$',
  ...[scenario['year1'], scenario['year2'], scenario['year3'], scenario['year4'], scenario['year5']].reduce(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + (idx + 1)]: _calcForecastedAUM(acc['year' + idx], cur, partialCashFlowsSum['year' + (idx + 1)]),
    }),
    { year0: totalMarketValue }
  ),
});

export const _calcDaysCashOnHand = (currentForecastedAUM, currentDailyExpenseFactor) =>
  currentForecastedAUM / currentDailyExpenseFactor;

export const _getDaysCashOnHand = (forecastedAUM, financialMetrics, childIdx) => ({
  description: 'Days Cash on Hand',
  key: childIdx,
  measure: 'Days',
  ...[
    forecastedAUM['year0'],
    forecastedAUM['year1'],
    forecastedAUM['year2'],
    forecastedAUM['year3'],
    forecastedAUM['year4'],
    forecastedAUM['year5'],
  ].reduce(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + idx]: _calcDaysCashOnHand(cur, financialMetrics?.dailyExpenseFactor?.['year' + idx]),
    }),
    {}
  ),
});

export const _calcInvGainLoss = (previousYearForecastedAUM, currentYearRate) =>
  previousYearForecastedAUM * currentYearRate;

export const _getInvGainLoss = (scenario, forecastedAUM, childIdx) => ({
  description: 'Investment Gain/Loss',
  key: childIdx,
  measure: '$',
  ...[scenario['year1'], scenario['year2'], scenario['year3'], scenario['year4'], scenario['year5']].reduceRight(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + (idx + 1)]: _calcInvGainLoss(forecastedAUM['year' + idx], cur),
    }),
    { year0: 0 }
  ),
});

export const _calcInvReturn = (rateValue) => rateValue * 100;

export const _getInvReturn = (scenario, childIdx) => ({
  description: 'Investment Return',
  key: childIdx,
  measure: '%',
  year0: 0,
  year1: _calcInvReturn(scenario['year1']),
  year2: _calcInvReturn(scenario['year2']),
  year3: _calcInvReturn(scenario['year3']),
  year4: _calcInvReturn(scenario['year4']),
  year5: _calcInvReturn(scenario['year5']),
});
// #endregion Economic Scenarios Calculations

// #region Budget Calculations
export const _calcBudgetForecastedAUM = (previousYearForecastedAUM, totalCashFlowSum) =>
  previousYearForecastedAUM + totalCashFlowSum;

export const _getBudgetForecastedAUM = (totalMarketValue, totalCashFlowsSum, childIdx) => ({
  description: 'AUM',
  key: childIdx,
  measure: '$',
  ...[
    totalCashFlowsSum['year1'],
    totalCashFlowsSum['year2'],
    totalCashFlowsSum['year3'],
    totalCashFlowsSum['year4'],
    totalCashFlowsSum['year5'],
  ].reduce(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + (idx + 1)]: _calcBudgetForecastedAUM(acc['year' + idx], cur),
    }),
    { year0: totalMarketValue }
  ),
});

export const _getBudgetDaysCashOnHand = (budgetForecastedAUM, financialMetrics, childIdx) =>
  _getDaysCashOnHand(budgetForecastedAUM, financialMetrics, childIdx);

export const _calcBudgetInvGainLoss = (totalCashFlowSum, partialCashFlowSum) => totalCashFlowSum - partialCashFlowSum;

export const _getBudgetInvGainLoss = (totalCashFlowsSum, partialCashFlowsSum, childIdx) => ({
  description: 'Investment Gain/Loss',
  key: childIdx,
  measure: '$',
  ...[
    totalCashFlowsSum['year1'],
    totalCashFlowsSum['year2'],
    totalCashFlowsSum['year3'],
    totalCashFlowsSum['year4'],
    totalCashFlowsSum['year5'],
  ].reduce(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + (idx + 1)]: _calcBudgetInvGainLoss(cur, partialCashFlowsSum['year' + (idx + 1)]),
    }),
    { year0: 0 }
  ),
});

export const _calcBudgetInvReturn = (currentBudgetInvGainLoss, previousYearBudgetForecastedAUM) =>
  (currentBudgetInvGainLoss / previousYearBudgetForecastedAUM) * 100;

export const _getBudgetInvReturn = (budgetInvGainLoss, budgetForecastedAUM, childIdx) => ({
  description: 'Investment Return',
  key: childIdx,
  measure: '%',
  ...[
    budgetInvGainLoss['year1'],
    budgetInvGainLoss['year2'],
    budgetInvGainLoss['year3'],
    budgetInvGainLoss['year4'],
    budgetInvGainLoss['year5'],
  ].reduceRight(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + (idx + 1)]: _calcBudgetInvReturn(cur, budgetForecastedAUM['year' + idx]),
    }),
    { year0: 0 }
  ),
});

export const _getBudgetCase = (totalMarketValue, totalCashFlowsSum, financialMetrics, partialCashFlowsSum) => {
  const budgetForecastedAUM = _getBudgetForecastedAUM(totalMarketValue, totalCashFlowsSum, FORECASTED_AUM_CHILD_IDX);
  const budgetDaysCashOnHand = _getBudgetDaysCashOnHand(budgetForecastedAUM, financialMetrics, DCOH_CHILD_IDX);
  const budgetInvGainLoss = _getBudgetInvGainLoss(totalCashFlowsSum, partialCashFlowsSum, INV_GAIN_LOSS_CHILD_IDX);
  const budgetInvReturn = _getBudgetInvReturn(budgetInvGainLoss, budgetForecastedAUM, INV_RETURN_CHILD_IDX);
  return {
    description: 'Budget',
    key: BUDGET_INDEX,
    children: [budgetForecastedAUM, budgetDaysCashOnHand, budgetInvGainLoss, budgetInvReturn],
  };
};
// #endregion Budget Calculations

// #region Main Functions
export const _getEconomicScenarios = ({
  economicScenarios,
  financialMetrics,
  partialCashFlowsSum,
  study,
  totalCashFlowsSum,
  totalMarketValue,
}) => [
  _getBudgetCase(totalMarketValue, totalCashFlowsSum, financialMetrics, partialCashFlowsSum),
  ...economicScenarios.map((scenario, parentIdx) => {
    const forecastedAUM = _getForecastedAUM(totalMarketValue, scenario, partialCashFlowsSum, FORECASTED_AUM_CHILD_IDX);
    const daysCashOnHand = _getDaysCashOnHand(forecastedAUM, financialMetrics, DCOH_CHILD_IDX);
    const invGainLoss = _getInvGainLoss(scenario, forecastedAUM, INV_GAIN_LOSS_CHILD_IDX);
    const invReturn = _getInvReturn(scenario, INV_RETURN_CHILD_IDX);
    return {
      description: scenario.scenarioName,
      key: parentIdx + 1,
      children: [forecastedAUM, daysCashOnHand, invGainLoss, invReturn],
    };
  }),
];

export const _calcEconomicScenariosRelativeToBudget = (currentValue, budgetedValue) => currentValue - budgetedValue;

export const _getBudgetCaseChildValue = (calculatedEconomicScenarios, childDescription, objectKeyString) =>
  calculatedEconomicScenarios[BUDGET_INDEX].children.find((child) => child.description === childDescription)[
    objectKeyString
  ];

export const _getEconomicScenariosRelativeToBudget = (calculatedEconomicScenarios) =>
  calculatedEconomicScenarios.map((scenario) => ({
    description: scenario.description,
    key: scenario.key,
    children: scenario.children.map(({ description, key, measure, year0, year1, year2, year3, year4, year5 }) => ({
      description,
      key,
      measure,
      ...[year0, year1, year2, year3, year4, year5].reduce(
        (acc, cur, idx) => ({
          ...acc,
          ['year' + idx]: _calcEconomicScenariosRelativeToBudget(
            cur,
            _getBudgetCaseChildValue(calculatedEconomicScenarios, description, 'year' + idx)
          ),
        }),
        {}
      ),
    })),
  }));

export const _getMaxYear5ForecastedAUM = (_filteredItems) => {
  const forecastedAUMList = _filteredItems.map((filteredItem) =>
    filteredItem.children.find((child) => child.description === 'AUM')
  );
  const forecastedAUMYear5List = forecastedAUMList.map((forecastedAUMItem) => forecastedAUMItem.year5);
  return Math.max(...forecastedAUMYear5List);
};

export const _sortByBudgetBaseCaseYear5AumDesc = (calculatedEconomicScenarios) => [
  ...calculatedEconomicScenarios.reduce((acc, cur, idx, arr) => {
    const _filteredItems = arr.filter((item) => !acc.some((accItem) => accItem.description === item.description));
    return [
      ...acc,
      _filteredItems.find((filteredItem) => filteredItem.description.includes('Budget')) ||
        _filteredItems.find((filteredItem) => filteredItem.description.includes('Base Case')) ||
        _filteredItems.find((filteredItem) => {
          const maxForecastedAUM = _getMaxYear5ForecastedAUM(_filteredItems);
          const filteredItemForecastedAUMYear5 = filteredItem.children.find(
            (child) => child.description === 'AUM'
          ).year5;
          return filteredItemForecastedAUMYear5 === maxForecastedAUM;
        }) ||
        _filteredItems[0],
    ];
  }, []),
];

export const calcEconomicScenarios = (ui) => {
  const { selectedStudy, totalMarketValue, isRelativeToBudget } = ui;
  if (!selectedStudy?.economicScenarios) return [];
  if (!selectedStudy.study) return [];
  if (!selectedStudy.calculatedCashFlows) return [];

  const { partialSum: partialCashFlowsSum, totalSum: totalCashFlowsSum } = selectedStudy.calculatedCashFlows;
  if (!partialCashFlowsSum) return [];
  if (!totalCashFlowsSum) return [];

  const { economicScenarios, financialMetrics, study } = selectedStudy;
  if (!economicScenarios?.length) return [];

  const params = {
    economicScenarios,
    financialMetrics,
    partialCashFlowsSum,
    study,
    totalCashFlowsSum,
    totalMarketValue,
  };

  const calculatedEconomicScenarios = _getEconomicScenarios(params);
  const sortedCalculatedEconomicScenarios = _sortByBudgetBaseCaseYear5AumDesc(calculatedEconomicScenarios);

  return isRelativeToBudget
    ? _getEconomicScenariosRelativeToBudget(sortedCalculatedEconomicScenarios)
    : sortedCalculatedEconomicScenarios;
};
// #endregion Main Functions
