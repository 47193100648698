export const CATEGORIES = [
  { id: 1, name: 'Operation' },
  { id: 2, name: 'Debt' },
  { id: 3, name: 'Pension Contributions' },
];

// #region CashFlows Calculations
export const _getCategorySum = (cashFlows, category) =>
  cashFlows
    .filter((cashFlow) => cashFlow.categoryId === category.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        year0: acc.year0 + cur.year0,
        year1: acc.year1 + cur.year1,
        year2: acc.year2 + cur.year2,
        year3: acc.year3 + cur.year3,
        year4: acc.year4 + cur.year4,
        year5: acc.year5 + cur.year5,
      }),
      {
        description: category.name,
        categoryId: category.id,
        year0: 0,
        year1: 0,
        year2: 0,
        year3: 0,
        year4: 0,
        year5: 0,
      }
    );

export const _getSumByCategory = (cashFlows) => CATEGORIES.map((category) => _getCategorySum(cashFlows, category));

export const _getPartialCashFlowsSum = (sumByCategory) =>
  sumByCategory.reduce(
    (acc, cur) => ({
      year0: acc.year0 + cur.year0,
      year1: acc.year1 + cur.year1,
      year2: acc.year2 + cur.year2,
      year3: acc.year3 + cur.year3,
      year4: acc.year4 + cur.year4,
      year5: acc.year5 + cur.year5,
    }),
    { year0: 0, year1: 0, year2: 0, year3: 0, year4: 0, year5: 0 }
  );

export const _getTotalCashFlowsSum = (partialSum, study) => ({
  ...[
    partialSum['year0'],
    partialSum['year1'],
    partialSum['year2'],
    partialSum['year3'],
    partialSum['year4'],
    partialSum['year5'],
  ].reduce(
    (acc, cur, idx) => ({
      ...acc,
      ['year' + idx]: cur + study['budgetedInvestmentGainLossYear' + idx],
    }),
    { year0: 0 }
  ),
});
// #endregion CashFlows Calculations

// #region Main Functions
export const calcCashFlows = (ui) => {
  if (!ui.selectedStudy?.cashFlows?.length) return { sumByCategory: [], partialSum: {}, totalSum: {} };

  const {
    selectedStudy: { cashFlows, study },
  } = ui;

  const sumByCategory = _getSumByCategory(cashFlows);
  const partialSum = _getPartialCashFlowsSum(sumByCategory);
  const totalSum = _getTotalCashFlowsSum(partialSum, study);

  return {
    sumByCategory,
    partialSum,
    totalSum,
  };
};
// #endregion Main Functions
