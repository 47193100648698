import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { Row, Button, NavLink, Input, FormGroup, FormFeedback, Media } from 'reactstrap';
import {
  MainContainerRow,
  MainContainerCol,
  RegisterContainer,
  SkyanName,
  ImageContainer,
  RegisterContainerHeader,
  RegisterContainerBody,
} from './style';
import SquareLogo from '../assets/squareLogo.jpg';

import { registerUser, registerCaptchaReseted } from '../../actions';
import withCaptcha from './WithCaptcha';

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        email: '',
        firstName: '',
        lastName: '',
      },
      validating: {
        email: false,
        firstName: false,
        lastName: false,
      },
      valid: {
        email: false,
        firstName: false,
        lastName: false,
      },
    };
  }

  validatingAll = () => {
    return !_.some(this.state.validating, (valid) => !valid);
  };

  validateAll = () => {
    return !_.some(this.state.valid, (valid) => !valid);
  };

  handleRegister = () => {
    const validating = _.mapValues(this.state.validating, () => true);
    this.setState({
      validating,
    });
    if (this.validateAll()) {
      const user = {
        captcha: this.props.captcha,
        ...this.state.values,
      };
      this.props.registerUser(user);
    }
  };

  handleChange = (field) => (event) => {
    this.setState({
      values: {
        ...this.state.values,
        [field]: event.target.value,
      },
    });
  };

  updateValidation = (field, valid) => {
    if ((!this.state.validating[field] && !valid) || this.state.valid[field] === valid) {
      return;
    }
    this.setState({
      validating: { ...this.state.validating, [field]: true },
      valid: { ...this.state.valid, [field]: valid },
    });
  };

  createInputRenderer = (title, field, validator = null, inputProps = {}) => {
    const handleChange = this.handleChange(field);
    const hasErrors = () => validator && this.state.validating[field] && validator(this.state.values[field]);
    const handleBlur = () => {
      this.updateValidation(field, !hasErrors());
    };
    const resultFunction = () => {
      const error = hasErrors();
      const invalid = !!error;
      return (
        <Row>
          <FormGroup>
            <Input
              type="text"
              placeholder={title}
              invalid={invalid}
              value={this.state.values[field]}
              onChange={handleChange}
              onBlur={handleBlur}
              {...inputProps}
            />
            <FormFeedback>{error}</FormFeedback>
          </FormGroup>
        </Row>
      );
    };
    return resultFunction;
  };

  validationsForCurrentEmail = () => this.state.values.email && this.props.previousEmail === this.state.values.email;

  validateEmailPatternWithoutRegEx = (email) => {
    const at = email.indexOf('@');
    const dot = email.lastIndexOf('.');
    return (
      email.length > 0 &&
      at > 0 &&
      dot > 0 &&
      dot > at + 1 &&
      dot < email.length - 1 &&
      email[at + 1] !== '.' &&
      email.indexOf(' ') === -1 &&
      email.indexOf('..') === -1
    );
  };

  renderEmail = this.createInputRenderer(
    'Email',
    'email',
    (email) => {
      if (!this.validateEmailPatternWithoutRegEx(email)) return 'Please, type a valid email.';
      if (email.length < 3) return 'Please, type your email.';
      if (this.validationsForCurrentEmail()) {
        if (this.props.emailAlreadyRegistered)
          return (
            <span>
              You are already registered. <NavLink href="/">Click here to login.</NavLink>
            </span>
          );
        if (this.props.emailNoClientFound)
          return (
            <span>
              Could not find your email in our client list,{' '}
              <NavLink href="https://www.nepc.com/contact-us">please, contact your consultant.</NavLink>
            </span>
          );
      }
      return null;
    },
    { type: 'email' }
  );

  renderFirstName = this.createInputRenderer('First Name', 'firstName', (firstName) => {
    return firstName.length < 3 ? 'Please, type your first name.' : null;
  });

  renderLastName = this.createInputRenderer('Last Name', 'lastName', (lastName) => {
    return lastName.length < 3 ? 'Please, type your last name.' : null;
  });

  renderStatus() {
    if (this.validatingAll()) {
      if (this.props.success) {
        return (
          <span>
            Successfully registered, please check your email and <a href="/">login.</a>
          </span>
        );
      } else if (this.props.error) {
        return <span>Error! Could not create account!</span>;
      }
    }
    return null;
  }

  renderSubmit() {
    const valid = this.validatingAll() && this.validateAll();
    const disabled = !valid || this.props.loading || _.isEmpty(this.props.captcha);
    return (
      <Row>
        <FormGroup>
          <Button className="authContainer-btn" color="success" onClick={this.handleRegister} disabled={disabled}>
            Register
          </Button>
        </FormGroup>
      </Row>
    );
  }

  renderCaptcha() {
    if (this.props.wrongCaptcha) {
      this.props.registerCaptchaReseted();
      this.props.resetCaptcha();
    }
    return this.props.renderCaptcha();
  }

  renderRegisterForm() {
    return (
      <>
        <RegisterContainerHeader>
          <Media right object height="40px" src={SquareLogo} alt="NEPC Logo Square" />
        </RegisterContainerHeader>
        <RegisterContainerBody>
          <h2>Please enter your information</h2>
          {this.renderEmail()}
          {this.renderFirstName()}
          {this.renderLastName()}
          {this.renderCaptcha()}
          {this.renderStatus()}
          {this.renderSubmit()}
        </RegisterContainerBody>
      </>
    );
  }

  render() {
    if (this.props.authenticated) {
      return <Redirect to={{ pathname: '/' }} />;
    }
    return (
      <>
        <ImageContainer />
        <MainContainerRow>
          <MainContainerCol>
            <SkyanName>SKYAN</SkyanName>
            <RegisterContainer>{this.renderRegisterForm()}</RegisterContainer>
          </MainContainerCol>
        </MainContainerRow>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  registerCaptchaReseted: PropTypes.func.isRequired,
  previousEmail: PropTypes.string.isRequired,
  // Status
  authenticated: PropTypes.bool.isRequired,
  emailNoClientFound: PropTypes.bool.isRequired,
  emailAlreadyRegistered: PropTypes.bool.isRequired,
  wrongCaptcha: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  // Captcha
  renderCaptcha: PropTypes.func.isRequired,
  resetCaptcha: PropTypes.func.isRequired,
  captcha: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { okta } = state;
  return {
    authenticated: !!okta?.user,
    previousEmail: okta?.registering?.user?.email || '',
    emailNoClientFound: okta?.registering?.emailNoClientFound,
    emailAlreadyRegistered: okta?.registering?.emailAlreadyRegistered,
    success: okta?.registering.success,
    error: okta?.registering.error,
    wrongCaptcha: okta?.registering.wrongCaptcha,
    loading: state.ui.isLoading,
  };
};
export default connect(mapStateToProps, {
  registerUser,
  registerCaptchaReseted,
})(withCaptcha(Register));
