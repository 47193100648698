import { Constants } from '../../services/Constants';
import { CATEGORIES } from '../CashFlows/CalcCashFlows';

// #region Financial Metrics Calculations
export const _calcCapexDepreciationAndAmortization = (capitalExpenditure, depreciationAndAmortization) =>
  !depreciationAndAmortization ? 0 : (capitalExpenditure / depreciationAndAmortization) * 100;

export const _calcOperatingMargin = (operatingIncome, operatingRevenue) =>
  !operatingRevenue ? 0 : (operatingIncome / operatingRevenue) * 100;

export const _calcLongTermDebtCapitalization = (longTermDebt, unrestrictedAssets) =>
  !unrestrictedAssets ? 0 : (longTermDebt / unrestrictedAssets) * 100;

export const _calcOperatingEBIDAMargin = (operatingEBIDA, operatingRevenue) =>
  !operatingRevenue ? 0 : (operatingEBIDA / operatingRevenue) * 100;

export const _calcDailyExpenseFactor = (operatingExpense, depreciationAndAmortization) =>
  !depreciationAndAmortization
    ? Math.abs(operatingExpense) / 365
    : (Math.abs(operatingExpense) - depreciationAndAmortization) / 365;

export const _calcMaximumAnnualDebtServiceCoverage = (operatingIncome, debtInterestExpense, debtPrincipalPayments) =>
  !(debtInterestExpense || debtPrincipalPayments) ? 0 : operatingIncome / (debtInterestExpense + debtPrincipalPayments);

export const _calcLongTermDebtRate = (debtInterestExpense, longTermDebt) =>
  !longTermDebt ? 0 : (debtInterestExpense / longTermDebt) * 100;

export const _calcTotalDebtService = (debtInterestExpense, debtPrincipalPayments) =>
  debtInterestExpense + debtPrincipalPayments;
// #endregion Financial Metrics Calculations

// #region Financial Metrics
export const _getCapexDepreciationAndAmortization = (otherFinancialProjections) => {
  const { capitalExpenditure, depreciationAndAmortization } = otherFinancialProjections;
  return {
    isManualInput: false,
    ...[
      capitalExpenditure.year0,
      capitalExpenditure.year1,
      capitalExpenditure.year2,
      capitalExpenditure.year3,
      capitalExpenditure.year4,
      capitalExpenditure.year5,
    ].reduce(
      (acc, cur, idx) => ({
        ...acc,
        [`year${idx}`]: _calcCapexDepreciationAndAmortization(cur, depreciationAndAmortization[`year${idx}`]),
      }),
      {}
    ),
  };
};

export const _getOperatingMargin = ({ operatingIncome, operatingRevenue }) => ({
  isManualInput: false,
  year0: _calcOperatingMargin(operatingIncome.year0, operatingRevenue.year0),
  year1: _calcOperatingMargin(operatingIncome.year1, operatingRevenue.year1),
  year2: _calcOperatingMargin(operatingIncome.year2, operatingRevenue.year2),
  year3: _calcOperatingMargin(operatingIncome.year3, operatingRevenue.year3),
  year4: _calcOperatingMargin(operatingIncome.year4, operatingRevenue.year4),
  year5: _calcOperatingMargin(operatingIncome.year5, operatingRevenue.year5),
});

export const _getTotalDebtService = ({ debtInterestExpense, debtPrincipalPayments }) => ({
  isManualInput: false,
  year0: _calcTotalDebtService(debtInterestExpense.year0, debtPrincipalPayments.year0),
  year1: _calcTotalDebtService(debtInterestExpense.year1, debtPrincipalPayments.year1),
  year2: _calcTotalDebtService(debtInterestExpense.year2, debtPrincipalPayments.year2),
  year3: _calcTotalDebtService(debtInterestExpense.year3, debtPrincipalPayments.year3),
  year4: _calcTotalDebtService(debtInterestExpense.year4, debtPrincipalPayments.year4),
  year5: _calcTotalDebtService(debtInterestExpense.year5, debtPrincipalPayments.year5),
});

export const _getLongTermDebtCapitalization = ({ longTermDebt, unrestrictedAssets }) => {
  return {
    isManualInput: false,
    ...[
      longTermDebt.year0,
      longTermDebt.year1,
      longTermDebt.year2,
      longTermDebt.year3,
      longTermDebt.year4,
      longTermDebt.year5,
    ].reduce(
      (acc, cur, idx) => ({
        ...acc,
        [`year${idx}`]: _calcLongTermDebtCapitalization(cur, unrestrictedAssets[`year${idx}`]),
      }),
      {}
    ),
  };
};

export const _getLongTermDebtRate = ({ debtInterestExpense, longTermDebt }) => ({
  isManualInput: false,
  year0: _calcLongTermDebtRate(debtInterestExpense.year0, longTermDebt.year0),
  year1: _calcLongTermDebtRate(debtInterestExpense.year1, longTermDebt.year1),
  year2: _calcLongTermDebtRate(debtInterestExpense.year2, longTermDebt.year2),
  year3: _calcLongTermDebtRate(debtInterestExpense.year3, longTermDebt.year3),
  year4: _calcLongTermDebtRate(debtInterestExpense.year4, longTermDebt.year4),
  year5: _calcLongTermDebtRate(debtInterestExpense.year5, longTermDebt.year5),
});

export const _getOperatingEBIDAMargin = ({ operatingEBIDA, operatingRevenue }) => ({
  isManualInput: false,
  year0: _calcOperatingEBIDAMargin(operatingEBIDA.year0, operatingRevenue.year0),
  year1: _calcOperatingEBIDAMargin(operatingEBIDA.year1, operatingRevenue.year1),
  year2: _calcOperatingEBIDAMargin(operatingEBIDA.year2, operatingRevenue.year2),
  year3: _calcOperatingEBIDAMargin(operatingEBIDA.year3, operatingRevenue.year3),
  year4: _calcOperatingEBIDAMargin(operatingEBIDA.year4, operatingRevenue.year4),
  year5: _calcOperatingEBIDAMargin(operatingEBIDA.year5, operatingRevenue.year5),
});

export const _getMaximumAnnualDebtServiceCoverage = (otherFinancialProjections) => {
  const { operatingIncome, debtInterestExpense, debtPrincipalPayments } = otherFinancialProjections;
  return {
    isManualInput: false,
    ...[
      operatingIncome.year0,
      operatingIncome.year1,
      operatingIncome.year2,
      operatingIncome.year3,
      operatingIncome.year4,
      operatingIncome.year5,
    ].reduce(
      (acc, cur, idx) => ({
        ...acc,
        [`year${idx}`]: _calcMaximumAnnualDebtServiceCoverage(
          cur,
          debtInterestExpense[`year${idx}`],
          debtPrincipalPayments[`year${idx}`]
        ),
      }),
      {}
    ),
  };
};

export const _getDailyExpenseFactor = ({ operatingExpense, depreciationAndAmortization }) => ({
  isManualInput: false,
  year0: _calcDailyExpenseFactor(operatingExpense.year0, depreciationAndAmortization.year0),
  year1: _calcDailyExpenseFactor(operatingExpense.year1, depreciationAndAmortization.year1),
  year2: _calcDailyExpenseFactor(operatingExpense.year2, depreciationAndAmortization.year2),
  year3: _calcDailyExpenseFactor(operatingExpense.year3, depreciationAndAmortization.year3),
  year4: _calcDailyExpenseFactor(operatingExpense.year4, depreciationAndAmortization.year4),
  year5: _calcDailyExpenseFactor(operatingExpense.year5, depreciationAndAmortization.year5),
});

// #endregion Financial Metrics
export const calcFinancialMetrics = (ui) => {
  if (!ui.selectedStudy?.otherFinancialProjections) return;
  if (!ui.selectedStudy?.cashFlows) return;
  const {
    selectedStudy: { otherFinancialProjections, cashFlows },
  } = ui;

  const {
    depreciationAndAmortization,
    longTermDebt,
    operatingEBIDA,
    operatingIncome,
    unrestrictedAssets,
    debtInterestExpense,
    debtPrincipalPayments,
  } = otherFinancialProjections;
  const operationCategory = CATEGORIES.find((category) => category.name === 'Operation');
  const operatingRevenue = cashFlows.find(
    (cashFlow) =>
      cashFlow.categoryId === operationCategory.id && cashFlow.description === Constants.OPERATING_REVENUE_TITLE
  );
  const operatingExpense = cashFlows.find(
    (cashFlow) =>
      cashFlow.categoryId === operationCategory.id && cashFlow.description === Constants.OPERATING_EXPENSE_TITLE
  );

  return {
    capexDepreciationAndAmortization: _getCapexDepreciationAndAmortization(otherFinancialProjections),
    dailyExpenseFactor: _getDailyExpenseFactor({ operatingExpense, depreciationAndAmortization }),
    longTermDebtCapitalization: _getLongTermDebtCapitalization({ longTermDebt, unrestrictedAssets }),
    longTermDebtRate: _getLongTermDebtRate({ debtInterestExpense, longTermDebt }),
    maximumAnnualDebtServiceCoverage: _getMaximumAnnualDebtServiceCoverage(otherFinancialProjections),
    operatingEBIDAMargin: _getOperatingEBIDAMargin({ operatingEBIDA, operatingRevenue }),
    operatingMargin: _getOperatingMargin({ operatingIncome, operatingRevenue }),
    totalDebtService: _getTotalDebtService({ debtInterestExpense, debtPrincipalPayments }),
  };
};
// #endregion Main Functions
