import axios from 'axios';

import Constants from './Constants';

const oktaService = () => {
  const URL = Constants.OKTA.URL;

  const registerUser = (user) => axios.post(`${URL}/register`, user);

  return {
    registerUser,
  };
};

export default oktaService;
