import axios from 'axios';
import _ from 'lodash';

import Constants from './Constants';
import { getHTTPHeaders } from './common';
import { isFixedField } from '../actions';
import { putIfAbsent } from '../utils/utils';

const sqlService = () => {
  const URL = Constants.SQL.URL;

  const operatingRevenue = {
    fixed: true,
    description: 'Operating Revenue (OR)',
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    categoryId: 1,
    index: 1,
    key: '1-1',
  };

  const operatingExpense = {
    fixed: true,
    description: 'Operating Expense (OE)',
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    categoryId: 1,
    index: 2,
    key: '1-2',
  };

  const getStudiesData = (clientGuid) => axios.get(`${URL}/study/client/${clientGuid}`, getHTTPHeaders());

  const getStudyData = (studyId) => axios.get(`${URL}/study/${studyId}`, getHTTPHeaders());

  const saveStudyData = (study) => axios.post(`${URL}/study`, study, getHTTPHeaders());

  const deleteStudy = (studyId) => axios.delete(`${URL}/study/${studyId}`, getHTTPHeaders());

  const cloneStudy = (studyId) => axios.post(`${URL}/study/${studyId}/clone`, {}, getHTTPHeaders());

  const createStudy = (newStudyData) =>
    axios.post(
      `${URL}/study`,
      {
        study: {
          assumptionId: newStudyData.assumptionId,
          studyName: newStudyData.studyName,
          planGuid: newStudyData.planGuid,
          clientGuid: newStudyData.clientGuid,
          projectedAum5Years: 0,
          projectedReturn5Years: 0,
          expectedReturnPool: 0,
          standardDeviationPool: 0,
        },
        cashFlows: [operatingRevenue, operatingExpense],
        assetPools: [],
        otherFinancialProjections: {},
        financialMetrics: {},
      },
      getHTTPHeaders()
    );

  const studyMapper = (entireStudyData) => {
    if (entireStudyData) {
      const hasFixeds = Boolean(
        entireStudyData.cashFlows.filter((cashFlow) => isFixedField(cashFlow.description)).length > 1
      );
      const addMissingCashFlows = (personalNodes) => {
        let missingCashFlows = [...putIfAbsent(personalNodes, 'description', operatingExpense)];
        missingCashFlows = [...putIfAbsent(missingCashFlows, 'description', operatingRevenue)];
        return missingCashFlows;
      };
      const concatedCashFlows = () => {
        if (hasFixeds) {
          return [
            ...entireStudyData.cashFlows.map((_cashFlow) => ({
              ..._cashFlow,
              fixed: isFixedField(_cashFlow.description),
            })),
          ];
        }
        const updatedKeyFlow = entireStudyData.cashFlows.map((_cashFlow) => {
          let idOffset = 2;
          if (isFixedField(_cashFlow)) {
            idOffset--;
            return fixedsCashFlows[_cashFlow.description];
          }
          return {
            ..._cashFlow,
            fixed: false,
            index: _cashFlow.index + idOffset,
          };
        });

        return [...addMissingCashFlows(updatedKeyFlow)];
      };

      const doc = {
        ...entireStudyData,
        cashFlows: [...concatedCashFlows()],
      };
      return doc;
    }
  };

  const studiesMapper = (studies) => {
    if (studies) {
      const docs = _.map(studies, (study) => {
        return {
          studyId: _.get(study, 'studyId'),
          studyName: _.get(study, 'studyName'),
          lastUpdate: _.get(study, 'updatedTs'),
          updatedBy: _.get(study, 'updatedBy'),
          createdDate: _.get(study, 'createdTs'),
          createdBy: _.get(study, 'createdBy'),
        };
      });
      return docs;
    }
  };

  return {
    cloneStudy,
    createStudy,
    deleteStudy,
    getStudiesData,
    getStudyData,
    saveStudyData,
    studiesMapper,
    studyMapper,
  };
};

export default sqlService;
