import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resetCredentials, setCredentials, loadAuthorization } from '../actions';
import { shouldReadTermsAndConditions } from './terms/actions';
import termsService from '../services/termsService';
import { dispatchHasAccepted } from './terms/actions';

const Authenticated = (props) => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (!props.okta) {
      props.shouldReadTermsAndConditions();
      return;
    }

    if (!authState?.isAuthenticated && props.okta) {
      props.resetCredentials();
      return;
    }

    const termsId = props.terms?.data?.id;
    if (termsId) {
      return function cleanup() {
        termsService()
          .hasAccepted(props.okta?.email, termsId)
          .then((response) => {
            if (!response.data?.email) {
              props.dispatchHasAccepted(false);
            } else {
              props.dispatchHasAccepted(true);
            }
          })
          .catch(() => {
            props.dispatchHasAccepted(true);
          });
      };
    }
  }, [authState, props.terms, props.okta]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const username = authState.idToken?.claims?.name;
      const token = authState.accessToken?.accessToken;
      if (username !== props.okta?.username || token !== props.okta?.token) {
        props.setCredentials(authState);
        props.loadAuthorization();
        return;
      }
    }
  }, [authState, props.okta]);
};

Authenticated.propTypes = {
  dispatchHasAccepted: PropTypes.func,
  loadAuthorization: PropTypes.func,
  okta: PropTypes.object,
  resetCredentials: PropTypes.func,
  setCredentials: PropTypes.func,
  shouldReadTermsAndConditions: PropTypes.func,
  terms: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    okta: state.ui.okta,
    terms: state.terms.latest,
  };
};

export default connect(mapStateToProps, {
  dispatchHasAccepted,
  loadAuthorization,
  resetCredentials,
  setCredentials,
  shouldReadTermsAndConditions,
})(Authenticated);
