import styled from 'styled-components';

const DefaultBox = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 8px 8px 0px 0px;

  margin: 12px 0px;
`;

export default DefaultBox;
