export const black1 = '#191919';
export const black2 = '#2b2b2b';
export const black3 = '#333';

export const gray1 = '#43505E';
export const gray2 = '#8596A8';
export const gray3 = '#F4F7FB';
export const gray4 = '#F1F0F0';
export const gray5 = '#ececec';
export const gray6 = '#d9d9d9';

export const blue1 = '#002060'; // nepc blue
export const blue2 = '#16709E'; // middle blue
export const blue3 = '#004879';
export const blue4 = '#6ED0F7'; // sky blue

export const green1 = '#8CC94A';

export const headerbigheight = '250px';
export const headersmallheight = '250px';
export const footerbigheight = '90px';
export const footersmallheight = '45px';

export const chartcolors = [blue1, blue2, blue4, green1, gray2];
