import React from 'react';
import ManagementButton from '../ManagementButton';
import { UnauthorizedContainer, BackgroundContainer, Error401 } from './style';

const Unauthorized = () => (
  <UnauthorizedContainer>
    <BackgroundContainer />
    <Error401>
      <h1>401</h1>
      <h2>Unauthorized</h2>
      <div className="log-in-again">
        <a href="/login">
          <ManagementButton btnText="Log in again" id="404-return-btn" border />
        </a>
      </div>
    </Error401>
  </UnauthorizedContainer>
);

export default Unauthorized;
