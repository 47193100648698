import React from 'react';
import ManagementButton from '../ManagementButton';
import { NotFoundContainer, BackgroundContainer, Error404 } from './style';

const Unauthorized = () => {
  const returnToPreviusPage = () => {
    return window.history.back();
  };

  return (
    <NotFoundContainer>
      <BackgroundContainer />
      <Error404>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <div className="return-btn">
          <ManagementButton action={returnToPreviusPage} btnText="Return" id="404-return-btn" border />
        </div>
      </Error404>
    </NotFoundContainer>
  );
};

export default Unauthorized;
