import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import {
  changeMeasure,
  dispatchCleanUpCalcValues,
  dispatchUpdateStudy,
  downloadAssumptionsPdf,
  saveStudy,
  selectAssumption,
  toggleSettingsMenu,
} from '../../actions';

import {
  GroupForm,
  HeaderSubText,
  HeaderText,
  InputWithLabel,
  InputWithoutLabel,
  SideMenu,
  SideMenuContent,
  SideMenuForm,
  SideMenuHeader,
  SideMenuOverlay,
} from './styles';
import ManagementButton from '../ManagementButton';

const SettingsMenu = (props) => {
  const { assumptions, selectedStudy, showOpenedMenu } = props;

  const {
    clientName,
    columnHead0Year,
    columnHead1Year,
    columnHead2Year,
    columnHead3Year,
    columnHead4Year,
    columnHead5Year,
    decimalPrecision,
    targetDCOH,
    targetDCOH2,
    targetForecastedAum,
    targetForecastedAum2,
    targetInvGainLoss,
    targetInvGainLoss2,
    targetInvReturn,
    targetInvReturn2,
    targetLabel,
    targetLabel2,
  } = props.selectedStudy.study;

  const scaleOptions = [
    { measureSymbol: '', measureLabel: 'None', measureId: 1 },
    { measureSymbol: '000', measureLabel: 'Thousands (000)', measureId: 2 },
    { measureSymbol: 'M', measureLabel: 'Millions (M)', measureId: 3 },
    { measureSymbol: 'B', measureLabel: 'Billions (B)', measureId: 4 },
  ];

  const precisionOptions = [
    { value: 0, name: '0' },
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
  ];

  const [openedMenu, setOpenedMenu] = useState();
  const [selectedAssumptionId, setSelectedAssumptionId] = useState(selectedStudy.study.assumptionId || null);
  const [measureOption, setMeasureOption] = useState(
    scaleOptions.find((item) => item.measureId === selectedStudy?.study?.measureScaleId) || {
      measureSymbol: '',
      measureLabel: 'None',
      measureId: 1,
    }
  );

  useEffect(() => {
    setOpenedMenu(showOpenedMenu);
  }, [showOpenedMenu]);

  const handleOpenedMenuClick = () => {
    setOpenedMenu(!openedMenu);
    props.toggleSettingsMenu();
  };

  const handleAssumptionChange = (value) => {
    setSelectedAssumptionId(value);
    props.selectAssumption(value);
    props.dispatchCleanUpCalcValues();
  };

  const handleMeasureOptionChange = (value) => {
    setMeasureOption(value);
    props.changeMeasure(value);
  };

  return (
    <SideMenu openedMenu={openedMenu}>
      <SideMenuOverlay onClick={() => handleOpenedMenuClick()} />
      <SideMenuContent>
        <SideMenuHeader>
          <HeaderText>Settings</HeaderText>
          <ManagementButton
            action={() => handleOpenedMenuClick()}
            backIcon="pi pi-times"
            circle={true}
            border={false}
          />
        </SideMenuHeader>
        <SideMenuForm>
          <GroupForm>
            <HeaderText>Client Name</HeaderText>
            <HeaderSubText>Choose the client name that will appear in the PDF.</HeaderSubText>
            <InputWithoutLabel>
              <InputText
                id="client-name-input"
                className="single-input-text"
                value={clientName || ''}
                onChange={(event) => props.dispatchUpdateStudy({ clientName: event.target.value })}
              />
            </InputWithoutLabel>
          </GroupForm>
          <GroupForm>
            <HeaderText>Assumption</HeaderText>
            <HeaderSubText>Choose Assumption to perform calculations.</HeaderSubText>
            <InputWithoutLabel>
              <Dropdown
                value={selectedAssumptionId}
                options={assumptions}
                optionLabel="versionName"
                optionValue="versionPk"
                id="assumptions-select-dropdown"
                onChange={(event) => handleAssumptionChange(event.value)}
                placeholder="Select an assumption"
                itemTemplate={(option) => {
                  return <span className={`product-badge`}>{option.versionName}</span>;
                }}
              />
            </InputWithoutLabel>
            <Button
              onClick={() => props.downloadAssumptionsPdf()}
              label="Assumption Details"
              className="p-button-link"
              id="assumptions-details-link"
            />
          </GroupForm>
          <GroupForm>
            <HeaderText>Scale and Precision</HeaderText>
            <HeaderSubText>Choose Scale and Precision for charts and tables.</HeaderSubText>
            <InputWithLabel>
              <label>Scale</label>
              <div className="dropdown">
                <Dropdown
                  value={measureOption}
                  options={scaleOptions}
                  optionLabel="measureLabel"
                  id="measure-label-dropdown-select"
                  onChange={(event) => handleMeasureOptionChange(event.value)}
                  itemTemplate={(option) => {
                    return <span className={`product-badge`}>{option.measureLabel}</span>;
                  }}
                />
              </div>
            </InputWithLabel>
            <InputWithLabel>
              <label>Precision</label>
              <div className="dropdown">
                <Dropdown
                  value={decimalPrecision}
                  options={precisionOptions}
                  optionValue="value"
                  optionLabel="name"
                  id="precision-dropdown-select"
                  onChange={(event) => props.dispatchUpdateStudy({ decimalPrecision: event.value })}
                />
              </div>
            </InputWithLabel>
          </GroupForm>
          <GroupForm>
            <HeaderText>Target Lines on Overview of Scenarios</HeaderText>
            <HeaderSubText>Adjust target lines as needed.</HeaderSubText>
            <InputWithLabel>
              <label>Target Label</label>
              <InputText
                value={targetLabel || ''}
                onChange={(event) => props.dispatchUpdateStudy({ targetLabel: event.target.value })}
                id="target-label-1"
              />
              <InputText
                value={targetLabel2 || ''}
                onChange={(event) => props.dispatchUpdateStudy({ targetLabel2: event.target.value })}
                id="target-label-2"
              />
            </InputWithLabel>
            <InputWithLabel>
              <label>AUM (${measureOption.measureSymbol})</label>
              <InputNumber
                value={targetForecastedAum || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetForecastedAum: event.value })}
                inputMode="number"
                id="target-forecasted-aum-1"
              />
              <InputNumber
                value={targetForecastedAum2 || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetForecastedAum2: event.value })}
                inputMode="number"
                id="target-forecasted-aum-2"
              />
            </InputWithLabel>
            <InputWithLabel>
              <label>DCOH (Days)</label>
              <InputNumber
                value={targetDCOH || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetDCOH: event.value })}
                inputMode="number"
                id="target-dcoh-1"
              />
              <InputNumber
                value={targetDCOH2 || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetDCOH2: event.value })}
                inputMode="number"
                id="target-dcoh-2"
              />
            </InputWithLabel>
            <InputWithLabel>
              <label>Inv. Gain/Loss (${measureOption.measureSymbol})</label>
              <InputNumber
                value={targetInvGainLoss || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetInvGainLoss: event.value })}
                inputMode="number"
                id="target-inv-gainloss-1"
              />
              <InputNumber
                value={targetInvGainLoss2 || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetInvGainLoss2: event.value })}
                inputMode="number"
                id="target-inv-gainloss-2"
              />
            </InputWithLabel>
            <InputWithLabel>
              <label>Inv. Return (%)</label>
              <InputNumber
                value={targetInvReturn || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetInvReturn: event.value })}
                inputMode="number"
                id="target-inv-return-1"
              />
              <InputNumber
                value={targetInvReturn2 || 0}
                onChange={(event) => props.dispatchUpdateStudy({ targetInvReturn2: event.value })}
                inputMode="number"
                id="target-inv-return-2"
              />
            </InputWithLabel>
          </GroupForm>
          <GroupForm>
            <HeaderText>Projected Years Settings</HeaderText>
            <HeaderSubText>Adjust column headings as needed.</HeaderSubText>
            <InputWithLabel>
              <label htmlFor="column-head0-year">Year 0</label>
              <InputText
                id="column-head0-year"
                className="single-input-text"
                value={columnHead0Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead0Year: event.target.value })}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label htmlFor="column-head1-year">Year 1</label>
              <InputText
                id="column-head1-year"
                className="single-input-text"
                value={columnHead1Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead1Year: event.target.value })}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label htmlFor="column-head2-year">Year 2</label>
              <InputText
                id="column-head2-year"
                className="single-input-text"
                value={columnHead2Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead2Year: event.target.value })}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label htmlFor="column-head3-year">Year 3</label>
              <InputText
                id="column-head3-year"
                className="single-input-text"
                value={columnHead3Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead3Year: event.target.value })}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label htmlFor="column-head4-year">Year 4</label>
              <InputText
                id="column-head4-year"
                className="single-input-text"
                value={columnHead4Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead4Year: event.target.value })}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label htmlFor="column-head5-year">Year 5</label>
              <InputText
                id="column-head5-year"
                className="single-input-text"
                value={columnHead5Year || ''}
                onChange={(event) => props.dispatchUpdateStudy({ columnHead5Year: event.target.value })}
              />
            </InputWithLabel>
          </GroupForm>
        </SideMenuForm>
      </SideMenuContent>
    </SideMenu>
  );
};

SettingsMenu.propTypes = {
  assumptions: PropTypes.array,
  changeMeasure: PropTypes.func,
  dispatchCleanUpCalcValues: PropTypes.func,
  dispatchUpdateStudy: PropTypes.func,
  downloadAssumptionsPdf: PropTypes.func,
  saveStudy: PropTypes.func,
  selectAssumption: PropTypes.func,
  selectedStudy: PropTypes.object,
  showOpenedMenu: PropTypes.bool,
  toggleSettingsMenu: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    assumptions: state.resources.assumptions.data || [],
    selectedStudy: state.ui.selectedStudy || {},
    showOpenedMenu: state.ui.showOpenedMenu || false,
  };
};

export default connect(mapStateToProps, {
  changeMeasure,
  dispatchCleanUpCalcValues,
  dispatchUpdateStudy,
  downloadAssumptionsPdf,
  saveStudy,
  selectAssumption,
  toggleSettingsMenu,
})(SettingsMenu);
