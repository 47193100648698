import { NotificationManager } from 'react-notifications';

import * as types from './types';
import { requestAction } from '../../utils/Request';
import termsService from '../../services/termsService';
import { withLoading } from '../../actions/CommonActions';
import { redirect } from '../../actions/UIActions';
import Constants from '../../services/Constants';

export const loadLatest = withLoading(() => {
  return (dispatch) => {
    return dispatch(requestAction(types.TERMS_LATEST, termsService().fetchLatest));
  };
});

export const loadAll = withLoading(() => {
  return (dispatch) => {
    return dispatch(requestAction(types.TERMS_ALL, termsService().fetchAll));
  };
});

export const save = withLoading((terms, success) => {
  return (dispatch, getState) => {
    const {
      okta: {
        user: { email },
      },
    } = getState();
    const today = new Date().toISOString();
    if (!terms.createdBy) {
      return save(
        {
          ...terms,
          createdBy: email,
          createdOn: today,
          active: true,
        },
        success
      );
    }
    return dispatch(
      requestAction(types.TERMS_SAVE, termsService().save, {
        ...terms,
        modifiedBy: email,
        modifiedOn: today,
      })
    )
      .then(() => {
        if (success) {
          success();
        }
        dispatch(loadLatest()).then(() => {
          dispatch(loadAll()).then(() => {
            NotificationManager.success(
              'Terms and Conditions successfully saved.',
              'Terms and Conditions',
              Constants.POP_UP_TIME
            );
          });
        });
      })
      .catch(() => {
        NotificationManager.error('Error saving terms and conditions.', 'Terms and Conditions', Constants.POP_UP_TIME);
      });
  };
});

export const acceptTermsAndConditions = withLoading(() => {
  return (dispatch, getState) => {
    const {
      terms: {
        latest: { data: currentTerms },
      },
      ui: {
        okta: { email },
      },
    } = getState();

    return termsService()
      .accept(email, currentTerms)
      .then(() => {
        dispatch({ type: types.TERMS_ACCEPTED });
        dispatch(redirect('/'));
      });
  };
});

export const shouldReadTermsAndConditions = withLoading(() => {
  return (dispatch) => {
    return dispatch(loadLatest());
  };
});

export const dispatchHasAccepted = (accepted) => {
  return (dispatch) => {
    switch (accepted) {
      case true: {
        return dispatch({ type: types.TERMS_ACCEPTED });
      }
      case false: {
        return dispatch({ type: types.TERMS_MUST_ACCEPT });
      }
      default:
        return dispatch({ type: types.TERMS_MUST_ACCEPT });
    }
  };
};
