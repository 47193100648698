import { OktaAuth } from '@okta/okta-auth-js';
import config from '../components/Login/config';

const oktaAuth = new OktaAuth(config.oidc);

export const getHTTPHeaders = () => {
  const tokens = oktaAuth?.tokenManager.getTokensSync();
  if (!tokens?.accessToken || oktaAuth.tokenManager.hasExpired(tokens.accessToken)) {
    if (oktaAuth?.isAuthenticated()) {
      oktaAuth.signOut();
      window.onbeforeunload = undefined;
    }
    throw new Error('Missing or expired token');
  }
  return {
    headers: { Authorization: `${tokens.accessToken.tokenType} ${tokens.accessToken.accessToken}` },
  };
};
