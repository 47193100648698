import * as types from './Types';
import oktaService from '../services/oktaService';
import { withLoading } from './CommonActions';
import { DEFAULT_REGISTERING } from '../reducers/OktaReducer';

export const registerUser = withLoading((user) => {
  return (dispatch, getState) => {
    return oktaService()
      .registerUser(user)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            _registerUpdate(user, {
              success: true,
            })
          );
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          // Unauthorized 401 = invalid captcha
          dispatch(
            _registerUpdate(user, {
              wrongCaptcha: true,
            })
          );
        } else {
          dispatch(
            _registerUpdate(user, {
              error: true,
            })
          );
        }
      });
  };
});

const _registerUpdate = (user, status) => {
  return (dispatch) => {
    const registering = { ...DEFAULT_REGISTERING, ...status, user };
    const eventType = registering.success ? types.REGISTER_USER_SUCCESS : types.REGISTER_USER_FAILED;
    dispatch({ type: eventType, registering });
  };
};

export const registerCaptchaReseted = () => {
  return (dispatch, getState) => {
    const {
      okta: {
        registering: { user },
      },
    } = getState();
    dispatch(
      _registerUpdate(user, {
        wrongCaptcha: false,
      })
    );
  };
};
