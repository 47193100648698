import axios from 'axios';

import Constants from './Constants';
import { getHTTPHeaders } from './common';

const eventService = () => {
  const getEventURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://clienthub-qa.nepc.com/events/api/v1';
      case 'QA':
        return 'https://clienthub-qa.nepc.com/events/api/v1';
      case 'UAT':
        return 'https://clienthub-uat.nepc.com/events/api/v1';
      case 'PROD':
        return 'https://clienthub.nepc.com/events/api/v1';
    }
  };

  const EVENTS_URL = getEventURL();

  const addEvent = (event) => axios.post(`${EVENTS_URL}/eventAdd`, event, getHTTPHeaders());

  const eventsByType = (eventType) => axios.post(`${EVENTS_URL}/eventsByType/${eventType}`, {}, getHTTPHeaders());

  const feedback = (feedbackParams) => axios.post(`${EVENTS_URL}/feedback`, feedbackParams, getHTTPHeaders());

  const getFeedbacks = () => axios.get(`${EVENTS_URL}/feedback`, getHTTPHeaders());

  return {
    addEvent,
    feedback,
    getFeedbacks,
    eventsByType,
  };
};

export default eventService;
