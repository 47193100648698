import axios from 'axios';

import Constants from './Constants';
import { openFile } from '../actions';

const printService = () => {
  const getPrintURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://clienthub-qa.nepc.com/print/api/v1';
      case 'QA':
        return 'https://clienthub-qa.nepc.com/print/api/v1';
      case 'UAT':
        return 'https://clienthub-uat.nepc.com/print/api/v1';
      case 'PROD':
        return 'https://clienthub.nepc.com/print/api/v1';
    }
  };

  const generatePdf = (data, template, orientation, contents) =>
    axios.post(
      `${getPrintURL()}/generatePdf`,
      { template, contents, orientation, data },
      {
        responseType: 'arraybuffer',
      }
    );

  const printHtmlPdf = (data, fileName, template, orientation, contentHTML) => {
    return generatePdf(data, template, orientation, contentHTML).then(({ data: file }) => {
      openFile(fileName, file);
    });
  };

  return {
    printHtmlPdf,
  };
};

export default printService;
