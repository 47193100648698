import axios from 'axios';
import _ from 'lodash';

import Constants from './Constants';
import { getHTTPHeaders } from './common';
import { openFile } from '../actions';

const processorService = () => {
  const PROCESSORURL = Constants.PROCESSOR.URL;

  const createBlob = (contents, mimeType = 'application/pdf') => {
    const blob = new Blob([contents], {
      type: mimeType,
    });
    const binaryMimeTypes = ['application/octet-stream', 'text/plain'];
    const isBinary = _.includes(binaryMimeTypes, mimeType);
    return !isBinary && URL.createObjectURL(blob);
  };

  const exportStudyDataToXlsx = (study) =>
    axios.post(`${PROCESSORURL}/study/export/xlsx`, study, {
      ...getHTTPHeaders(),
      responseType: 'arraybuffer',
    });

  const calcRiskReturn = (riskReturnRequest) => {
    const client = axios.create({ baseURL: PROCESSORURL });
    return client.post(`/riskreturn`, riskReturnRequest, getHTTPHeaders());
  };

  const exportStudyDataToExcel = (study) => {
    return exportStudyDataToXlsx(study).then(({ data: file }) => {
      openFile(`${study.study.studyName}.xlsx`, file, 'application/octet-stream');
    });
  };

  const getAssumptionsPdf = (email, assumptionId, assumptionName) =>
    axios
      .get(`${PROCESSORURL}/assumptionsDetails/${assumptionId}?email=${email}`, {
        ...getHTTPHeaders(),
        responseType: 'arraybuffer',
      })
      .then(({ data: file }) => {
        const url = createBlob(file, 'application/pdf');
        const isIE = (window.navigator && !!window.navigator.msSaveOrOpenBlob) || false;
        const wind = url && !isIE && window.open(url);
        if (!wind) {
          openFile(`${assumptionName}.pdf`, file);
        }
      });

  const getOperationGuidePdf = (email) =>
    axios
      .get(`${PROCESSORURL}/operating-guide?email=${email}`, {
        ...getHTTPHeaders(),
        responseType: 'arraybuffer',
      })
      .then(({ data: file }) => {
        const url = createBlob(file, 'application/pdf');
        const isIE = (window.navigator && !!window.navigator.msSaveOrOpenBlob) || false;
        const wind = url && !isIE && window.open(url);
        if (!wind) {
          openFile('OperationGuide.pdf', file);
        }
      });

  return {
    calcRiskReturn,
    exportStudyDataToExcel,
    getAssumptionsPdf,
    getOperationGuidePdf,
  };
};

export default processorService;
