import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  display: flex;
  height: 520px;

  align-content: center;
  justify-content: center;
  background: #002060;
`;

export const Error404 = styled.div`
  z-index: 2;
  background-color: white;
  text-align: center;
  color: #183672;
  align-content: center;
  justify-content: center;
  padding-top: 10rem;
  margin-top: 6rem;
  height: 520px;
  border-radius: 3px;
  width: 400px;
  .return-btn {
    margin-top: 10rem;
    text-align: center;
    text-align: -webkit-center;
    * {
      width: 80px;
    }
  }
`;

export const BackgroundContainer = styled.div`
  background-image: url('background.png');
  position: absolute;
  top: 0;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 520px;
  min-height: 520px;
  overflow-y: hidden;
`;
