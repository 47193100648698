import React from 'react';
import styled from 'styled-components';

export class NoDataAvailable extends React.Component {
  render() {
    return (
      <StyledNoDataAvailable className="d-flex justify-content-center align-items-center">
        No Data Available
      </StyledNoDataAvailable>
    );
  }
}

const StyledNoDataAvailable = styled.div`
  color: #002060;
  font-size: 18px;
  flex: 1 0 0px;
  overflow: auto;
`;

export default NoDataAvailable;
