/**
 * This function compares year by year from year0 to year5 and returns if they are equal.
 * @param {object} obj1 First object to be compared.
 * @param {object} obj2 Second object to be compared.
 * @return {boolean} Returns true if all values match from year0 to year5, otherwise, false.
 */
export const isEqualFromYear0 = (obj1, obj2) =>
  obj1 &&
  obj2 &&
  obj1.year0 === obj2.year0 &&
  obj1.year1 === obj2.year1 &&
  obj1.year2 === obj2.year2 &&
  obj1.year3 === obj2.year3 &&
  obj1.year4 === obj2.year4 &&
  obj1.year5 === obj2.year5;

/**
 * This function compares year by year from year1 to year5 and returns if they are equal.
 * @param {object} obj1 First object to be compared.
 * @param {object} obj2 Second object to be compared.
 * @return {boolean} Returns true if all values match from year1 to year5, otherwise, false.
 */
export const isEqualFromYear1 = (obj1, obj2) =>
  obj1 &&
  obj2 &&
  obj1.year1 === obj2.year1 &&
  obj1.year2 === obj2.year2 &&
  obj1.year3 === obj2.year3 &&
  obj1.year4 === obj2.year4 &&
  obj1.year5 === obj2.year5;

/**
 * This function deeply compares two objects and returns if they are equal.
 * @param {object} obj1 First object to be compared.
 * @param {object} obj2 Second object to be compared.
 * @return {boolean} Returns true if all attributes and nested objects match, otherwise, false.
 */
export const isDeepEqual = (obj1, obj2) => {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);
  return str1 === str2;
};

/**
 * This function formats the number to print PDF.
 * @param {object} value Value to be formatted.
 * @param {object} decimalPrecision Precision to format the value.
 * @return {boolean} Returns the number in the formatted decimal precision to print PDF.
 */
export const formatPrecisionNumber = (value, decimalPrecision = 0) =>
  `${Number.parseFloat(value || 0).toLocaleString('en-US', {
    maximumFractionDigits: decimalPrecision,
    minimumFractionDigits: decimalPrecision,
  })}`;

export const putIfAbsent = (array, key, value) => {
  if (array.length > 0) {
    if (!array.some((node) => node[key].includes(value[key]))) {
      return [value, ...array];
    }
  }
  return [value];
};

export const slugToId = (string) => {
  return string.replaceAll(/\W/g, '-').replaceAll('--', '-').toLowerCase();
};
