import styled from 'styled-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Constants from '../../../services/Constants';
import Cards from '../../Cards/Cards';
import Study from '../../Study/Study';
import TermsAndConditions from '../../terms/TermsAndConditions';
import viewParams from '../../router/ViewParams';
import NoAccessPage from '../../NoSkyanAccess/NoSkyanPage';
import SettingsPanel from '../../SettingsPanel';

const ContentStyle = styled.div`
  grid-area: content;
  background: #f4f7fb;
  min-height: calc(100vh - 45px - 150px);
`;

export class Content extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { selectedView } = this.props;
    const contents = {
      [Constants.CARDS_VIEW]: Cards,
      [Constants.STUDY_VIEW]: Study,
      [Constants.TERMS_AND_CONDITIONS_VIEW]: TermsAndConditions,
      [Constants.SETTINGS_MENU_VIEW]: SettingsPanel,
      [Constants.NO_SKYAN_ACCESS]: NoAccessPage,
    };
    const Comp = contents[selectedView];
    if (Comp) {
      return (
        <ContentStyle>
          <Comp viewParams={this.props.viewParams} />
        </ContentStyle>
      );
    }
    return null;
  }
}

Content.propTypes = {
  selectedView: PropTypes.string.isRequired,
  match: PropTypes.object,
  viewParams,
};

export default withRouter(Content);
