import styled from 'styled-components';

const StyledOtherFinancialProjections = styled.div`
  padding-top: 20px;
  .sm-visible {
    display: none;
  }

  .p-inputnumber-input {
    width: 50%;
  }

  .other-financial-projections-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 4px 15px 4px 24px;
    margin: 0 15px 8px 0;
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }
  tr.has-child-true {
    .description-body {
      .description {
        width: calc(100% - 30px);
      }
    }
  }
  .description-body {
    text-align: left;
    .description {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      #manual-input-oi,
      #manual-input-tds,
      #manual-input-ua {
        padding: 0 0.3rem 0 1.5rem;
        display: inline-flex;
        gap: 5px;
        label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 125px;
          max-height: 1.5rem;
        }
      }
      .form-check-inline {
        margin-right: 0px;
        .form-check-input {
          border-radius: 4px;
          border: 2px solid #ced4da;
          margin-top: 8px;
          transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
        }
      }
      .info {
        .btn {
          padding-top: 0px;
          padding-bottom: 0px;
          &#operatingebida-info {
            margin-right: 0rem;
            padding: 0;
          }
        }
      }
    }
  }

  .description-header,
  .p-inputtext {
    text-align: left;
    padding: 0 0.3rem 0 1.5rem;
  }

  tr > th {
    color: #000000;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    background-color: #b6b6b6;
    white-space: nowrap;
    overflow: initial;
  }

  td,
  th {
    color: #002060;
    border: 1px solid #b6b6b6;
    font-weight: 600;
  }

  .p-treetable-tbody {
    color: #002060;
    max-width: 73px;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .p-inputtext {
    text-align: right;
    color: #002060;
    background: transparent;
    padding: 0rem 0.2rem;
    font-weight: 600;
    border: 0px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #6ed0f7;
    border-color: #6ed0f7;
  }

  p {
    line-height: 1.5;
    margin: 0 0 1rem 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .flag {
    vertical-align: middle;
  }

  span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
  }

  .p-treetable .p-treetable-thead > tr {
    > th {
      font-weight: bold;
      background: #c9d4de;
      .p-column-header-content {
        justify-content: center;
      }
    }
    > th.description-header {
      .p-column-header-content {
        justify-content: start;
      }
    }
  }

  .p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #495057;
    outline-color: #a6d5fa;
    font-weight: 400;
    .p-button {
      color: transparent;
      border: transparent;
      background: transparent;
    }
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #dbe7f2;
    color: #495057;
    .p-button {
      transition: color 0.5s;
      color: #16709e;
    }
  }

  tr:hover {
    .p-button.p-button-danger.p-button-text {
      transition: color 0.5s;
      color: black;
    }
  }

  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #dbe7f2;
    color: #495057;
    .p-button {
      transition: color 0.5s;
      color: #16709e;
    }
  }

  .has-child-true.is-child-false {
    .description-body {
      padding: 0 4px 0 0;
    }
    .p-treetable-toggler {
      padding: 0px 5px 0px 5px;
      margin: 0 !important;
      font-size: 16px;
    }
  }

  .has-child-false {
    .p-treetable-toggler {
      display: none;
    }
  }

  .is-child-true {
    .description-body {
      padding: 0 0 0 1rem;
    }
    .p-treetable-toggler {
      margin: 0 !important;
    }
  }

  .is-grand-child-true {
    .description-body {
      padding: 0 0 0 2rem;
    }
  }

  .has-child-false.is-grand-child-false.is-child-false {
    .description-body {
      padding: 0 4.5px;
    }
  }

  .read-only-from-year0:not(.is-manual-input-true) {
    > td:not(.description-body) {
      background: rgb(242, 242, 242);
    }
  }

  .read-only-from-year1:not(.is-manual-input-true) {
    > td:not(.description-body):not(.year0-body) {
      background: rgb(242, 242, 242);
    }
  }

  .marked-true > td:not(.description-body) {
    background: rgb(242, 242, 242);
  }
`;

export default StyledOtherFinancialProjections;
