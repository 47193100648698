import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundContainer, MaintenanceModeContainer, ReasonArea, ReasonImage } from './style';

const MaintenanceScreen = (props) => (
  <MaintenanceModeContainer>
    <BackgroundContainer />
    <ReasonArea>
      <ReasonImage src={props.maintenanceMode} alt="Under maintenance" />
    </ReasonArea>
  </MaintenanceModeContainer>
);

MaintenanceScreen.propTypes = {
  maintenanceMode: PropTypes.string,
};

export default MaintenanceScreen;
