import React from 'react';
import { Provider } from 'react-redux';

import AppRouter from './components/router/AppRouter';
import 'react-notifications/lib/notifications.css';
import configStore from './store/configStore';

const store = configStore();

function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
