import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button, UncontrolledTooltip } from 'reactstrap';

import Constants from '../../services/Constants';
import DefaultBox from '../DefaultBox';
import { StyledFinancialMetrics, CellBody } from './StyledFinancialMetrics';
import { dispatchSetFinancialMetrics } from '../../actions';
import { calcFinancialMetrics } from './CalcFinancialMetrics';
import { formatPrecisionNumber, isDeepEqual } from '../../utils/utils';

const FinancialMetrics = (props) => {
  const { calculatedFinancialMetrics, measureScale, selectedStudy } = props;

  const { decimalPrecision } = selectedStudy.study;

  const getParsedNodes = (financialMetrics) => {
    const rightOrder = Object.keys(Constants.FINANCIAL_METRICS_DEFS);
    return rightOrder.map((key) => {
      return {
        description: key,
        ...Constants.FINANCIAL_METRICS_DEFS[key],
        ...financialMetrics[key],
      };
    });
  };

  const [nodesState, setNodesState] = useState([]);

  useEffect(() => {
    setNodesState(getParsedNodes(selectedStudy.financialMetrics));
  }, [selectedStudy.financialMetrics]);

  useEffect(() => {
    if (!isDeepEqual(selectedStudy.financialMetrics, calculatedFinancialMetrics)) {
      props.dispatchSetFinancialMetrics(calculatedFinancialMetrics);
    }
  }, [calculatedFinancialMetrics]);

  const getFormattedNumber = (value, measure) => {
    const _decimalPrecision = measure === 'x' ? 1 : measure === '%' ? 2 : decimalPrecision;
    return `${formatPrecisionNumber(value, _decimalPrecision)}${measure}`;
  };

  const cellBody = (rowData, colBodyOptions) => (
    <CellBody>{getFormattedNumber(rowData[colBodyOptions.field], rowData.measure)}</CellBody>
  );

  const rowClassName = (node) => {
    const especificRowClassName = `financial-metrics-row marked-${node.markedRow} readOnly-${node.readOnly} `;
    return { [especificRowClassName]: true };
  };

  const addMeasureScale = (title) => {
    if (measureScale.measureSymbol && title.includes('($)')) {
      return title.replace('$', '$' + measureScale.measureSymbol);
    }
    return title;
  };

  const getDescriptionBody = (rowData) => (
    <div className="with-info">
      <div id={`${rowData.description}-title`} className="description">
        {addMeasureScale(rowData.title)}
      </div>
      <div className="info">
        <Button id={`${rowData.description}-info`} color="link" className="link-info">
          <i className="pi pi-info-circle"></i>
        </Button>
        <UncontrolledTooltip placement="top" trigger="hover" target={`${rowData.description}-info`}>
          {rowData.info}
        </UncontrolledTooltip>
      </div>
    </div>
  );

  return (
    <DefaultBox fullBorder={false} id="financial-metrics-card">
      <StyledFinancialMetrics>
        <div className="financial-metrics-header">
          <h2>Projected Financial Metrics</h2>
        </div>

        {Boolean(nodesState) && (
          <div className="financial-metrics-table">
            <DataTable
              className="financial-metrics"
              responsiveLayout="scroll"
              rowClassName={rowClassName}
              stripedRows
              value={nodesState}
            >
              <Column
                header="Description"
                headerClassName="description-header"
                body={(rowData) => getDescriptionBody(rowData)}
                bodyClassName="description-body"
                style={{ width: '35%' }}
              ></Column>
              <Column
                field="year0"
                bodyClassName="year0-body"
                header={selectedStudy.study.columnHead0Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="year1"
                bodyClassName="year1-body"
                header={selectedStudy.study.columnHead1Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="year2"
                bodyClassName="year2-body"
                header={selectedStudy.study.columnHead2Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="year3"
                bodyClassName="year3-body"
                header={selectedStudy.study.columnHead3Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="year4"
                bodyClassName="year4-body"
                header={selectedStudy.study.columnHead4Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="year5"
                bodyClassName="year5-body"
                header={selectedStudy.study.columnHead5Year}
                body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
            </DataTable>
          </div>
        )}
      </StyledFinancialMetrics>
    </DefaultBox>
  );
};

FinancialMetrics.propTypes = {
  calculatedFinancialMetrics: PropTypes.object,
  dispatchSetFinancialMetrics: PropTypes.func,
  measureScale: PropTypes.object,
  selectedStudy: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    calculatedFinancialMetrics: calcFinancialMetrics(state.ui) || {},
    measureScale: state.ui.measureScale,
    selectedStudy: state.ui.selectedStudy || {},
  };
};

export default connect(mapStateToProps, { dispatchSetFinancialMetrics })(FinancialMetrics);
