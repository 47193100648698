import React from 'react';
import styled from 'styled-components';

export const SettingsPanel = styled.div``;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-between;
  padding-bottom: 30px;
  text-align: center;
  text-justify: center;
  margin-top: 0px;
`;

export const MenuForm = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #6c757d;
  border-radius: 10px;
  width: 80vw;
  padding: 2rem;
  background: #ffffff;
  s .dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 7px;
    .p-component {
      color: #002060;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      border-radius: 7px;
      width: 200px;
    }
  }

  .p-panel {
    .p-panel-title {
      cursor: pointer;
    }
    ul {
      margin: 0.5rem 0rem;
    }
  }
`;

export const InputWithLabel = styled.div`
  display: inline-flex;
  margin: 7px 0px;
  align-items: baseline;
  justify-content: space-between;
  gap: 3px;
  label {
    white-space: nowrap;
    width: 37%;
    flex: 1 0 auto;
  }
  .p-inputtext.single-input-text {
    flex: 1 0 auto;
  }
  input {
    padding: 2px 3px;
    width: 60%;
  }
`;

export const InputWithoutLabel = styled.div`
  margin-bottom: 5px;
  .p-inputtext {
    width: 100%;
    padding: 0.4rem;
    &.p-dropdown-label {
      margin: 0px 0px;
    }
  }
  .p-dropdown {
    background: #ffffff;
    border: 1px ridge #4950573f;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 7px;
    padding: 2px 5px;
    width: 100%;
  }
`;

export const GroupForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border: 1px #002060;
  .p-button-link {
    text-align: right;
  }
`;

export const HeaderSubText = styled.span`
  font-size: 14px;
`;

export const HeaderText = styled.h2``;

export const MaintenanceOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 1rem;

  .field-radiobutton {
    display: inline-flex;
    align-items: center;
  }
  .p-radiobutton {
    margin: 0rem 0.5rem;
  }
`;

export const MaintenanceButtons = styled.div`
  display: inline-flex;
  margin: 1rem 0;
  gap: 1rem;
`;

export const DisclaimerHeadertemplate = (options) => {
  const toggleIcon = options.collapsed ? 'pi pi-caret-right' : 'pi pi-caret-down';
  const className = `${options.className} justify-content-start`;
  const titleClassName = `${options.titleClassName} pl-1`;

  return (
    <div className={className} onClick={options.onTogglerClick}>
      <button id="disclaimer-toggle-icon" className={options.togglerClassName} onClick={options.onTogglerClick}>
        <span className={toggleIcon}></span>
      </button>
      <span className={titleClassName} onClick={options.onTogglerClick}>
        Disclaimer
      </span>
    </div>
  );
};
