import _ from 'lodash';

import eventService from '../services/eventService';

const buildEvent = (state, eventType, eventData = null, userName) => {
  const {
    ui: { selectedStudy },
  } = state;
  const timeStamp = new Date().toISOString();
  const studyGuid = selectedStudy?.study?.studyId || null;
  return {
    userName,
    timeStamp,
    eventType,
    studyGuid,
    eventData,
  };
};

export const addEvent = (eventType, eventData = null) => {
  return (dispatch, getState) => {
    const {
      ui: { okta },
    } = getState();
    const datas = _.isArray(eventData) ? eventData : [eventData];
    const requests = _.map(datas, (d) => buildEvent(getState(), eventType, d, okta?.username));
    return eventService().addEvent(requests);
  };
};

export const addLoginEvent = (eventType, okta) => {
  return (dispatch, getState) => {
    const userName = okta?.idToken?.claims?.name;
    const datas = _.isArray(userName) ? userName : [userName];
    const requests = _.map(datas, (d) => buildEvent(getState(), eventType, d, userName));
    return eventService().addEvent(requests);
  };
};
