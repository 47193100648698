import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import reducers from '../reducers';

export const history = createBrowserHistory();

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(routerMiddleware(history)), applyMiddleware(thunk))
);

export default function configStore() {
  return store;
}
