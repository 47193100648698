import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledManagementButton = styled.div`
  position: static;
  background: #ffffff;
  ${(props) =>
    props.onlyIcon === true
      ? 'max-width: 30px;max-height: 30px;padding: 15px;align-content: center;'
      : 'max-height: 40px;border-radius: 8px; padding: 0.5rem 0.75rem;'}
  @media screen and (max-width: 405px) {
    ${(props) =>
      props.onlyIcon === true
        ? 'max-width: 30px; max-height: 30px;padding: 15px;align-content: center;'
        : 'max-height: 40px;border-radius: 8px; padding: 0.5rem 1.5rem;'}
  }
  ${(props) =>
    props.useCircle === true ? 'border-radius: 50%;background-color: transparent;' : ''} justify-content: center;
  white-space: nowrap;

  display: flex;
  flex-grow: 0;

  cursor: pointer;
  font-weight: 600;
  border: ${(props) => (props.useBorder === true ? '1px' : '0px')} solid #c9d4de;
  box-sizing: border-box;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  ${(props) => (props.disabled ? 'cursor: context-menu;opacity: .65;pointer-events:none;' : 'cursor: pointer;')}

  ${(props) =>
    props.showOnHover
      ? 'color: transparent;background: transparent;:hover {background: transparent;color: #002060;}'
      : 'color: #002060;:hover {background: #16709E;color: #FFFFFF;}'}

  i {
    margin: ${(props) => (props.onlyIcon === true ? '0;' : '0 5px 0 0;')};
  }
`;

const ManagementButton = (props) => {
  const { action, backIcon, border, btnText, circle, disabled, frontIcon, id, showOnHover } = props;

  const onlyIcon = (backIcon || frontIcon) && !btnText;

  return (
    <StyledManagementButton
      className="p-button"
      disabled={disabled}
      id={id}
      onClick={action}
      onlyIcon={onlyIcon}
      showOnHover={showOnHover}
      useBorder={border}
      useCircle={circle}
    >
      {backIcon && <i className={`pi ${backIcon} `}></i>}
      {btnText}
      {frontIcon && <i className={`pi ${frontIcon} `}></i>}
    </StyledManagementButton>
  );
};

ManagementButton.propTypes = {
  action: PropTypes.func,
  backIcon: PropTypes.string,
  border: PropTypes.bool,
  btnText: PropTypes.string,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  frontIcon: PropTypes.string,
  id: PropTypes.string,
  showOnHover: PropTypes.bool,
};

export default ManagementButton;
