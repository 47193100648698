import _ from 'lodash';

import { requestActionOpts as baseRequestActionOpts, requestReducer, requestInitReducer } from '../utils/Request';
import processorService from '../services/processorService';
import sqlService from '../services/sqlService';
import denodoService from '../services/denodoService';
import MaintenanceService from '../services/maintenanceService';
import { withLoadingPromise } from './CommonActions';

export const resourcesTypes = {
  RESOURCES_ASSET_ALLOCATION: 'RESOURCES_ASSET_ALLOCATION',
  RESOURCES_ASSUMPTIONS: 'RESOURCES_ASSUMPTIONS',
  RESOURCES_CHART_DATA: 'RESOURCES_CHART_DATA',
  RESOURCES_CLIENTS: 'RESOURCES_CLIENTS',
  RESOURCES_CURRENTSTUDYRESOURCES: 'RESOURCES_CURRENTSTUDYRESOURCES',
  RESOURCES_RISK_PROXIES: 'RESOURCES_RISK_PROXIES',
  RESOURCES_STUDIES_DATA: 'RESOURCES_STUDIES_DATA',
  RESOURCES_MAINTENANCE_STATUS: 'RESOURCES_MAINTENANCE_STATUS',
};

export const loadChartDataResources = (skipCache, mapper) =>
  requestAction(resourcesTypes.RESOURCES_CHART_DATA, processorService().getData, { mapper, skipCache });

export const loadCashFlowsResources = () =>
  requestAction(resourcesTypes.RESOURCES_CASH_FLOWS_DATA, processorService().getCashFlowsData);

export const loadStudiesResources = (clientGuid) =>
  requestActionOpts(
    resourcesTypes.RESOURCES_STUDIES_DATA,
    sqlService().getStudiesData,
    {
      skipCache: true,
      mapper: sqlService().studiesMapper,
    },
    clientGuid
  );

export const loadRiskProxiesResources = (clientGuid) =>
  requestActionOpts(resourcesTypes.RESOURCES_RISK_PROXIES, denodoService().getRiskProxies, {}, clientGuid);

export const loadAssetAllocationResources = (mixIDs, allocatedBy) =>
  requestActionOpts(
    resourcesTypes.RESOURCES_ASSET_ALLOCATION,
    denodoService().getAssetAllocation,
    { skipCache: true },
    mixIDs,
    allocatedBy
  );

export const loadAssumptionsResources = () =>
  requestActionOpts(resourcesTypes.RESOURCES_ASSUMPTIONS, denodoService().getAssumptions, { skipCache: false });

export const loadClientResources = (email) =>
  requestActionOpts(resourcesTypes.RESOURCES_CLIENTS, denodoService().getClients, { skipCache: false }, email);

export const loadStudyResources = (studyId) => {
  return (dispatch) => {
    return dispatch(
      requestActionOpts(
        resourcesTypes.RESOURCES_CURRENTSTUDYRESOURCES,
        sqlService().getStudyData,
        { skipCache: true, mapper: sqlService().studyMapper },
        studyId
      )
    );
  };
};

export const checkMaintenanceStatus = () => {
  return (dispatch) => {
    return dispatch(
      requestActionOpts(resourcesTypes.RESOURCES_MAINTENANCE_STATUS, MaintenanceService().checkIsUnderMaintence, {
        skipCache: false,
      })
    );
  };
};

export const saveMappedCashFlowsResources = (mappedCashFlows) => {
  return (dispatch) => {
    return dispatch({
      type: resourcesTypes.RESOURCES_CASH_FLOWS_DATA,
      cashFlowsData: mappedCashFlows,
    });
  };
};

const typesAsArray = _.keys(resourcesTypes);

export const DEFAULT_STATE = {
  ...requestInitReducer(typesAsArray),
};

export const Reducer = (state = DEFAULT_STATE, action = {}) => {
  return requestReducer(state, typesAsArray, action);
};

const withLoadingService =
  (dispatch, service) =>
  (...args) => {
    return new Promise((resolve) => {
      const promise = service(...args);
      withLoadingPromise(dispatch, promise);
      return resolve(promise);
    });
  };

const requestActionMap = (type, service, mapper, ...args) => requestActionOpts(type, service, { mapper }, ...args);

const requestAction = (type, service, ...args) => requestActionMap(type, service, (o) => o, ...args);

const requestActionOpts =
  (type, service, opts, ...args) =>
  (dispatch) =>
    dispatch(baseRequestActionOpts(type, withLoadingService(dispatch, service), opts, ...args));
