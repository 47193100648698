import styled from 'styled-components';
import * as colors from '../../StyleConstants';

export const StyledHeader = styled.div`
  position: relative;
  text-align: left;

  background-image: url('/background-header.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: 0px 30%;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    background-position: 0% 35%;
  }

  padding: 1rem;

  display: inline-flex;
  justify-content: space-between;
  width: 100vw;
  height: 144px;

  @media screen and (max-width: 800px) {
    padding: 0.3rem;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-right: 1vw;
  align-items: flex-end;
  justify-content: center;

  .logout {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 5px;
  }

  .operating-guide {
    font-size: 1.15rem;
    white-space: nowrap;
    cursor: pointer;
  }

  .logout,
  .operating-guide {
    color: white;
    text-decoration: none;
    :hover {
      color: #002060;
      transition: color 0.2s;
    }
  }
  @media screen and (max-width: 405px) {
    .logout {
      font-size: 0.75rem;
      font-weight: 700;
      margin-top: 25px;
    }
    .operating-guide {
      font-size: 0.9rem;
    }
  }
`;

export const LeftContent = styled.div`
  display: inline-flex;
  align-items: baseline;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: space-around;
    margin-left: 15px;
  }
`;

export const Logo = styled.a`
  white-space: nowrap;
  align-self: center;
  img {
    max-height: 75px;
  }

  @media screen and (max-width: 800px) {
    align-self: baseline;
    img {
      max-height: 55px;
    }
  }
`;

export const LogoSquare = styled.div`
  /* margin-bottom: 0.5rem; */
  @media screen and (max-width: 405px) {
    height: 55px;
  }
  @media screen and (max-width: 310px) {
    height: 50px;
  }
`;

export const WelcomeMessage = styled.h4`
  font-size: 1.5rem;
  color: ${colors.gray5};
  align-self: end;
  margin: 13px 10px;

  @media screen and (max-width: 800px) {
    font-size: 1rem !important;
    align-self: center;
    margin: 0px 10px;
  }
`;
