export const SET_TOTAL_MARKET_VALUE = 'SET_TOTAL_MARKET_VALUE';
export const EMPTY_ASSET_POOL_DESCRIPTION = 'EMPTY_ASSET_POOL_DESCRIPTION';
export const EMPTY_ASSET_POOL_RISK_PROXY = 'EMPTY_ASSET_POOL_RISK_PROXY';
export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
export const CHART_DATA = 'CHART';
export const CASH_FLOWS_DATA = 'CASH_FLOWS_DATA';
export const CALCULATED_CASH_FLOWS = 'CALCULATED_CASH_FLOWS';
export const IS_RELATIVE_TO_BUDGET = 'IS_RELATIVE_TO_BUDGET';
export const DISPLAY_SINGLE_COLUMN_LAYOUT = 'DISPLAY_SINGLE_COLUMN_LAYOUT';
export const EDITED_CASH_FLOWS_DATA = 'EDITED_CASH_FLOWS_DATA';
export const SELECTED_ASSUMPTION = 'SELECTED_ASSUMPTION';
export const SELECTED_MEASURE = 'SELECTED_MEASURE';

export const EDITED_ASSET_POOLS = 'EDITED_ASSET_POOLS';

export const UPDATE_STUDY_DATA = 'UPDATE_STUDY_DATA';
export const UPDATE_OTHER_FINANCIAL_PROJECTIONS_DATA = 'UPDATE_OTHER_FINANCIAL_PROJECTIONS_DATA';
export const SET_FINANCIAL_METRICS_DATA = 'SET_FINANCIAL_METRICS_DATA';
export const SET_SELECTED_STUDY = 'SET_SELECTED_STUDY';
export const SET_TO_EXPORT_STUDY = 'SET_TO_EXPORT_STUDY';
export const SET_SELECTED_ALLOCATED_BY_VALUE = 'SET_SELECTED_ALLOCATED_BY_VALUE';
export const STORE_ECONOMIC_SCENARIOS = 'STORE_ECONOMIC_SCENARIOS';
export const STORE_AUM_AND_RETURN = 'STORE_AUM_AND_RETURN';
export const STUDY_RESET_DATA = 'STUDY_RESET_DATA';
export const MUST_REVERT = 'MUST_REVERT';

export const LOADING = 'LOADING';
export const REDIRECT = 'REDIRECT';

export const ROUTER = 'ROUTER';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const RESET_CREDENTIALS = 'RESET_CREDENTIALS';

export const TOGGLE_SETTINGS_MENU = 'TOGGLE_SETTINGS_MENU';

export const ORIGINAL_VERSION = 'ORIGINAL_VERSION';

export const IS_STUDY_VALID = 'IS_STUDY_VALID';
export const PRINTING = 'PRINTING';

export const USER_DATA = 'USER_DATA';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const SKYAN_ACCESS = 'SKYAN_ACCESS';

export const UPDATE_SELECTED_MAINTENANCE_FIELD = 'UPDATE_SELECTED_MAINTENANCE_FIELD';
export const MAINTENANCE_STATUS = 'MAINTENANCE_STATUS';
