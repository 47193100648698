import styled from 'styled-components';

export const StyledTermsAndConditions = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
  .p-button {
    font-size: 1.5rem;
    margin: 20px 30px 0 5vw;
  }
`;

export const TermsText = styled.div`
  max-width: 80vw;
  @media screen and (max-width: 400px) {
    max-width: 90vw;
    margin: 0 5vw;
  }
`;
