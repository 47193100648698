import styled from 'styled-components';

export const StyledFinancialMetrics = styled.div`
  padding-top: 20px;
  .sm-visible {
    display: none;
  }

  .financial-metrics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 4px 15px 4px 24px;
    margin: 0 15px 8px 0;
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .financial-metrics-table {
    margin-bottom: 0rem;
    margin-top: 5px;
    font-size: 14px;
    line-height: 32px;
    td {
      text-align: center;
      padding: 0 4px;
    }
    th {
      text-align: center;
      padding: 0 0.5rem;

      &.description .p-column-header-content {
        justify-content: start;
        padding-left: 1rem;
      }
    }
    tr {
      &:hover {
        background-color: #dbe7f2;
        .p-button.p-button-danger.p-button-text {
          transition: color 0.5s;
          color: black;
        }
      }
      &.p-row-odd {
        background: #f2f2f2;
      }
      > th {
        line-height: 32px;
      }
    }
    .description-body {
      text-align: left;
      .with-info {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        .info {
          .btn {
            padding: 0px;
          }
        }
      }
    }
    > :not(:last-child) > :last-child > * {
      border-bottom-color: #b6b6b6;
    }
  }
  input,
  button {
    line-height: initial;
  }
  .sm-visible {
    display: none;
  }

  .p-inputnumber-input {
    width: 50%;
  }
  .p-button {
    color: #16709e;
    background: #ffffff;
    padding: 0.2rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }

  .p-button:not(a):not(.p-disabled):hover {
    background: #16709e;
    color: #ffffff;
    border-color: #16709e;
  }

  tr > th {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    background: #c9d4de;
    white-space: nowrap;
    overflow: initial;
    margin-bottom: 0rem;

    br {
      content: '';
    }
    p {
      margin-block-end: 3px;
    }
  }

  td,
  th {
    border: 1px solid #b6b6b6;
    max-height: 30px;
  }

  .p-datatable-tbody {
    max-width: 73px;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
    padding-right: 5px;
  }

  .p-datatable .p-datatable-thead > tr {
    > th {
      font-weight: bold;
      background: #c9d4de;
      .p-column-header-content {
        justify-content: center;
      }
    }
    > th.description-header {
      text-align: left;
      padding: 0 4.5px;
      .p-column-header-content {
        justify-content: start;
        padding: 0 0.3rem 0 1.5rem;
      }
    }
  }
`;

export const CellBody = styled.span`
  display: flex;
  gap: 3px;
  margin: 0 0 0 3px;
  text-align: right;
  justify-content: right;
`;
