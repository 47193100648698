import axios from 'axios';

import Constants from './Constants';
import { getHTTPHeaders } from './common';

const termsService = () => {
  const getOktaURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://clienthub-qa.nepc.com/okta';
      case 'QA':
        return 'https://clienthub-qa.nepc.com/okta';
      case 'UAT':
        return 'https://clienthub-uat.nepc.com/okta';
      case 'PROD':
        return 'https://clienthub.nepc.com/okta';
    }
  };
  const getEventsURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://clienthub-qa.nepc.com/events/api/v1/termsAndConditions';
      case 'QA':
        return 'https://clienthub-qa.nepc.com/events/api/v1/termsAndConditions';
      case 'UAT':
        return 'https://clienthub-uat.nepc.com/events/api/v1/termsAndConditions';
      case 'PROD':
        return 'https://clienthub.nepc.com/events/api/v1/termsAndConditions';
    }
  };
  const TERMS_URL = getEventsURL();
  const OKTA_URL = getOktaURL();

  const save = (term) => axios.post(`${TERMS_URL}/save`, term, getHTTPHeaders());

  const fetchLatest = () => axios.get(`${OKTA_URL}/termsAndConditions/latest`);

  const fetchAll = () => axios.get(`${TERMS_URL}/list`, getHTTPHeaders());

  const accept = (email, terms) =>
    axios.post(
      `${TERMS_URL}/accept`,
      {
        email,
        acceptedDate: new Date().toISOString(),
        termsAndConditionsDto: terms,
      },
      getHTTPHeaders()
    );

  const hasAccepted = (email, termsId) =>
    axios.get(`${TERMS_URL}/checkAcceptance/${email}/${termsId}`, getHTTPHeaders());

  return {
    save,
    fetchLatest,
    fetchAll,
    accept,
    hasAccepted,
  };
};

export default termsService;
