import styled from 'styled-components';

export const StyledAssetsPools = styled.div`
  padding: 24px 0px 0px 0px;

  .p-inputwrapper {
    background-color: transparent;
  }

  input,
  button {
    line-height: initial;
  }
  .sm-visible {
    display: none;
  }

  .p-inputnumber-input {
    width: 50%;
  }

  .asset-pool-table {
    thead th:not(:first-child):not(:nth-child(2)) div {
      justify-content: center;
      text-align: center;
    }

    .standard-dev-body,
    .ten-year-exp-rtn-body,
    .standard-dev-footer,
    .ten-year-exp-rtn-footer {
      text-align: center;
    }
  }

  .asset-pool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 15px 8px 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .asset-pools-footer {
    margin: 5px 0px;
  }

  .p-datatable-footer {
    background-color: #c9d4de;
    display: flex;
    width: 100%;
    border: 1px solid #b6b6b6;
    border-top: none;
  }

  .p-datatable {
    margin: 0px 0px;
  }

  .pool-description {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;

    .colorFlag {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 3rem;
      margin-left: 0.5rem;
      margin-right: 0.3rem;
    }

    .p-editable-column,
    td {
      padding-right: 5px;
      overflow: hidden;
    }
    .p-inputtext {
      background: transparent;
      font-weight: 600;
    }
    .p-inputtext:enabled:focus,
    .p-inputtext {
      outline: 0 none;
      outline-offset: 0;
      border-color: transparent;
    }
  }

  tr:hover {
    background-color: #dbe7f2;
    .p-button {
      transition: color 0.5s;
      color: #16709e;
    }
  }

  .risk-proxy-header {
    max-width: 190px;
    .p-column-header-content {
      flex-direction: row-reverse;
      justify-content: center;
    }
    .p-button {
      color: #16709e;
      background: #ffffff;
      padding: 0.2rem 0.5rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 3px;
    }

    .p-button:not(a):not(.p-disabled):hover {
      background: #16709e;
      color: #ffffff;
      border-color: #16709e;
    }
  }

  .aum-header,
  .days-cash-on-hand-header,
  .ten-year-exp-rtn-header,
  .standard-dev-header {
    max-width: 250px;
    .p-column-header-content {
      flex-direction: row-reverse;
    }

    .p-editable-column,
    td {
      overflow: hidden;
    }
  }
  .days-cash-on-hand-header,
  .ten-year-exp-rtn-header,
  .standard-dev-header {
    text-align: center;
  }

  .days-cash-on-hand-header 
    .with-info {
      display: inline-flex !important;
      justify-content: space-between !important;
      width: 100%;
      .info {
        .btn {
          padding:0 0 0 1rem;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }
  }

  .aum-input {
    .p-inputtext {
      text-align: right;
      color: #002060;
      background: transparent;
      padding: 0rem 0.2rem;
      font-weight: 600;
      border: 0px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      appearance: none;
      border-radius: 3px;
    }

    .p-inputtext:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #6ed0f7;
      border-color: #6ed0f7;
    }
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  tr > th {
    color: #000000;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    padding-right: 5px;
    background-color: #c9d4de;
    max-height: 35px;
  }

  td,
  th {
    border: 1px solid #b6b6b6;
    max-height: 35px;
  }

  tr .reorder-button {
    border-right-width: 0px;
    border-left-width: 0px;
  }

  tr .asset-pools-description {
    border-left-width: 0px;
    &.description-body {
      .pool-description {
        display: flex;
      }
    }
  }

  .p-datatable-tbody {
    max-width: 73px;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
    padding-right: 5px;
  }

  .flag {
    vertical-align: middle;
  }

  span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
  }

  .days-cash-on-hand-body {
    font-size: 1rem;
    text-align: center;
  }

  .ten-year-exp-rtn-body,
  .standard-dev-body {
    font-size: 1rem;
    padding-right: 5px;
  }

  .p-datatable-tfoot > tr > td {
    font-weight: bold;
    background-color: rgb(242, 242, 242);

    &.description-footer {
      padding-left: 2rem;
    }

    &.aum-footer {
      text-align: right;
      padding-right: 5px;
    }

    &.days-cash-on-hand-footer,
    &.risk-proxy-footer {
      text-align: center;
      padding-right: 5px;
    }
  }
`;
