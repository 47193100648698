import styled from 'styled-components';

export const StyledAssetAllocation = styled.div`
  padding-top: 0px;

  .p-inputwrapper {
    background-color: transparent;
  }

  .asset-allocation-table {
    margin-bottom: 0rem;
    margin-top: 5px;
    font-size: 14px;
    line-height: 32px;
    td {
      text-align: center;
      padding: 0 0.5rem;
    }
    th {
      text-align: center;
      padding: 0 0.5rem;

      &.description .p-column-header-content {
        justify-content: start;
        padding-left: 1rem;
      }
    }
  }

  .asset-allocation-table > :not(:last-child) > :last-child > * {
    border-bottom-color: #b6b6b6;
  }

  .liquidity-note {
    color: #002060;
    font-size: 14px;
    line-height: 32px;
    margin-left: 10px;
  }

  input,
  button {
    line-height: initial;
  }
  .sm-visible {
    display: none;
  }

  .p-inputnumber-input {
    width: 50%;
  }

  tr:hover {
    background-color: #dbe7f2;
    .p-button.p-button-danger.p-button-text {
      transition: color 0.5s;
      color: black;
    }
  }

  .aum {
    text-align: justify;
    -moz-text-align-last: right;
    text-align-last: right;

    .p-editable-column,
    td {
      padding-right: 5px;
      overflow: hidden;
    }
    .p-inputtext {
      text-align: right;
      background: transparent;
      font-weight: 600;
    }
    .p-inputtext:enabled:focus,
    .p-inputtext {
      outline: 0 none;
      outline-offset: 0;
      border-color: transparent;
    }
  }

  .p-button {
    color: #16709e;
    background: #ffffff;
    padding: 0.2rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }

  .p-button:not(a):not(.p-disabled):hover {
    background: #16709e;
    color: #ffffff;
    border-color: #16709e;
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  tr > th {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    background: #c9d4de;
    white-space: nowrap;
    overflow: initial;
    margin-bottom: 0rem;

    br {
      content: '';
    }
    p {
      margin-block-end: 3px;
    }
  }

  td,
  th {
    border: 1px solid #b6b6b6;
    max-height: 30px;
  }

  .p-datatable-tbody {
    max-width: 73px;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
    padding-right: 5px;
  }

  .p-column-header-content {
    justify-content: center;
  }
`;

export const DescriptionBody = styled.div`
  display: flex;
  gap: 3px;
  margin: 0 0 0 3px;
  white-space: nowrap;
  overflow: hidden;
  span {
    padding-left: 10px;
  }
`;

export const CellBody = styled.span`
  display: flex;
  gap: 3px;
  margin: 0 0 0 3px;
  text-align: right;
  justify-content: center;
`;

export const ChartArea = styled.div`
  margin-top: -30px;
  z-index: 0;
  .p-row-odd {
    background: #f2f2f2;
  }
`;

export const AssetAllocationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px 4px 24px;
  width: 100%;
  h2,
  .header-inputs {
    z-index: 2;
  }
  .p-button-outlined {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    background: #ffffff;
    border: 1px solid #c9d4de;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .header-inputs {
    .dropdown {
      background: #ffffff;
      border: 1px solid #ced4da;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      border-radius: 4px;
      margin-right: 24px;
      padding: 5px 0px 5px 10px;
      .p-component {
        color: #002060;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
`;
