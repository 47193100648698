import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SquareLogo from '../../assets/squareLogo.jpg';
import SkyanLogo from '../../assets/SKYAN_White_LOGO.png';
import { downloadOperationGuidePdf, redirect, setMustRevert, isStudyModified } from '../../../actions';
import { StyledHeader, RightContent, Logo, LeftContent, WelcomeMessage, LogoSquare } from './StyledHeader';

const Header = (props) => {
  const { oktaAuth, authState } = useOktaAuth();

  const welcomeMessage = authState?.isAuthenticated ? `Welcome, ${authState.idToken.claims.name}` : '';

  const handlePrintOperationGuide = () => setTimeout(() => props.downloadOperationGuidePdf(), 200);

  const checkSaveStudyBeforeLogout = () => {
    if (location.pathname.includes('/study/') && props.isStudyModified()) {
      const tokens = oktaAuth?.tokenManager.getTokensSync();
      if (
        oktaAuth?.isAuthenticated() &&
        !oktaAuth.tokenManager.hasExpired(tokens.accessToken) &&
        window.confirm(
          'There are unsaved changes. Are you sure you want to leave this page?\nClick Cancel to stay and finish your work. Click OK to discard your changes.'
        )
      ) {
        props.setMustRevert();
        setTimeout(() => props.redirect('/logout'));
      }
    } else {
      props.redirect('/logout');
    }
  };

  return (
    <StyledHeader>
      <LeftContent>
        <Logo href="/">
          <img src={SkyanLogo} alt="Skyan Logo"></img>
        </Logo>
        <WelcomeMessage id="welcome-message" heading>
          {welcomeMessage}
        </WelcomeMessage>
      </LeftContent>
      <RightContent>
        <LogoSquare>
          <img height="75px" src={SquareLogo} alt="NEPC Logo Square"></img>
        </LogoSquare>
        <a href="#" onClick={() => checkSaveStudyBeforeLogout()} className="logout" id="logout">
          Sign Out
        </a>
        <span onClick={handlePrintOperationGuide} className="operating-guide" id="user-guide-link">
          SKYAN User Guide
        </span>
      </RightContent>
    </StyledHeader>
  );
};

Header.propTypes = {
  downloadOperationGuidePdf: PropTypes.func,
  originalVersion: PropTypes.object,
  redirect: PropTypes.func,
  selectedStudy: PropTypes.object,
  setMustRevert: PropTypes.func,
  isStudyModified: PropTypes.func,
};

const mapStateToProps = (state) => ({
  originalVersion: state.ui.originalVersion,
  selectedStudy: state.ui.selectedStudy,
});

export default connect(mapStateToProps, {
  downloadOperationGuidePdf,
  redirect,
  setMustRevert,
  isStudyModified,
})(Header);
