import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';

import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';

import moment from 'moment';

const INIT_STATUS = Object.freeze({
  START: Symbol('START'),
  LOADING_CLIENTS_DATA: Symbol('LOADING_CLIENTS_DATA'),
  READY_TO_LOAD_ASSUMPTIONS: Symbol('READY_TO_LOAD_ASSUMPTIONS'),
  LOADING_ASSUMPTIONS_DATA: Symbol('LOADING_ASSUMPTIONS_DATA'),
  DONE: Symbol('DONE'),
});

import { Card, TitleText, CardFooter, CardsMenu, CardText, CardTitle, StyledCards, ActionButtons } from './StyledCards';
import {
  cloneStudy,
  createNewStudy,
  deleteStudy,
  dispatchSelectedClient,
  loadClients,
  loadRiskProxies,
  loadStudiesData,
  selectStudy,
  redirect,
  loadAssumptions,
} from '../../actions';
import ManagementButton from '../ManagementButton';
import Constants from '../../services/Constants';

const getMixManagerUrl = (selectedClientGuid) => {
  switch (Constants.ENVIRONMENT) {
    case 'LOCAL':
      return `https://mixmanager-qa.nepc.com/${selectedClientGuid}/1`;
    case 'QA':
      return `https://mixmanager-qa.nepc.com/${selectedClientGuid}/1`;
    case 'UAT':
      return `https://mixmanager-uat.nepc.com/${selectedClientGuid}/1`;
    case 'PROD':
      return `https://mixmanager.nepc.com/${selectedClientGuid}/1`;
    default:
      return `https://mixmanager.nepc.com/${selectedClientGuid}/1`;
  }
};

export class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initStatus: INIT_STATUS.START,
      items: [],
      newStudyName: undefined,
      clientOptions: props.clientsData,
    };
  }

  componentDidMount() {
    if (this.props.selectedClient.clientGuid) {
      this.props.loadStudiesData();
    }
  }

  componentDidUpdate() {
    const propsAssumptionsIsEmpty = !this.props.assumptions.length;
    const propsClientsDataIsEmpty = !this.props.clientsData.length;
    const propsOktaTokenExists = this.props.okta?.token;

    const { initStatus } = this.state;

    if (initStatus !== INIT_STATUS.DONE && propsOktaTokenExists) {
      if (initStatus === INIT_STATUS.START && propsClientsDataIsEmpty) {
        this.setState({ initStatus: INIT_STATUS.LOADING_CLIENTS_DATA });
        this.props
          .loadClients()
          .then(() => {
            if (this.props.clientsData.length === 1) {
              this.setSelectedClient(this.props.clientsData[0]);
            }
            this.setState({ initStatus: INIT_STATUS.READY_TO_LOAD_ASSUMPTIONS, clientOptions: this.props.clientsData });
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/unauthorized' });
            } else {
              NotificationManager.error(error.response?.message || 'Network Error');
              oktaAuth.signOut();
            }
          });
      }
      if (initStatus === INIT_STATUS.READY_TO_LOAD_ASSUMPTIONS && propsAssumptionsIsEmpty) {
        this.setState({ initStatus: INIT_STATUS.LOADING_ASSUMPTIONS_DATA });
        this.props.loadAssumptions().then(() => {
          this.setState({ initStatus: INIT_STATUS.DONE });
        });
      }
    }
  }

  setSelectedClient = (selectedClient) => {
    if (selectedClient) {
      this.props.dispatchSelectedClient(selectedClient);
      this.props.loadStudiesData();
      this.props
        .loadRiskProxies(selectedClient.clientGuid)
        .then(() => {
          if (!this.props.riskProxies.length) {
            NotificationManager.error(
              'There are no Asset Allocation Proxy available. Please contact NEPC for support.',
              Constants.POP_UP_TIME
            );
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/unauthorized' });
          }
        });
    } else this.props.dispatchSelectedClient(selectedClient);
  };

  confirmDeleteStudy = (id) =>
    confirmDialog({
      message: 'This action will delete this study and all its values. Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.handleDeleteStudy(id),
    });

  confirmCreateStudy = () =>
    confirmDialog({
      message: () => this.createStudyInput(),
      header: 'Insert the study name',
      accept: () => this.handleCreateNewStudy(),
    });

  createStudyInput = () => {
    return (
      <InputText
        id="new-study-name"
        value={this.state.newStudyName}
        onChange={(event) => this.setState({ newStudyName: event.target.value })}
      />
    );
  };

  handleDeleteStudy = (id) => {
    this.props.deleteStudy(id).then(() => {
      this.props.loadStudiesData();
    });
  };

  handleCloneStudy = (id) => {
    this.props.cloneStudy(id).then(() => {
      this.props.loadStudiesData();
    });
  };

  handleCreateNewStudy = () => {
    // TODO: take the rights data when implement okta
    const studyData = {
      planGuid: '123',
      clientGuid: this.props.selectedClient.clientGuid,
      studyName: this.state.newStudyName,
    };
    this.props.selectStudy(null);
    this.props.createNewStudy(studyData).then(() => {
      this.props.loadStudiesData();
    });
  };

  handleSelectedClientChange = (e) => {
    this.setSelectedClient(this.props.clientsData.find((client) => client.clientGuid === e.clientGuid));
  };

  clientItemTemplate = (e) => {
    return <div onClick={() => this.handleSelectedClientChange(e)}>{e.name}</div>;
  };

  card = (data) => {
    const item = [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.confirmDeleteStudy(data.studyId),
      },
      {
        label: 'Clone',
        icon: 'pi pi-clone',
        command: () => this.handleCloneStudy(data.studyId),
      },
    ];

    const openCard = (event) => {
      if (event.target !== event.currentTarget) return;
      return this.props.redirect(`/study/${data.studyId}/${this.props.selectedClient.clientGuid}`);
    };

    return (
      <Card key={data.studyId} onClick={(event) => openCard(event)}>
        <CardTitle onClick={(event) => openCard(event)}>
          <i className="pi pi-chart-line" onClick={(event) => openCard(event)}></i>
          <TitleText
            className="title-text"
            onClick={(event) => openCard(event)}
            data-pr-tooltip={data.studyName}
            data-pr-position="top"
          >
            {data.studyName}
          </TitleText>
          <SplitButton dropdownIcon="pi pi-bars" className="bars-btn" model={item}></SplitButton>
        </CardTitle>
        <CardFooter>
          <CardText onClick={(event) => openCard(event)}>
            Updated:
            <div onClick={(event) => openCard(event)}>
              {moment(data.lastUpdate).format('MM/DD/YYYY hh:mm')}
              <div
                className="user-name"
                onClick={(event) => openCard(event)}
                data-pr-tooltip={data.updatedBy}
                data-pr-position="top"
              >
                by {data.updatedBy}
              </div>
            </div>
          </CardText>
          <CardText onClick={(event) => openCard(event)}>
            Created:
            <div onClick={(event) => openCard(event)}>
              {moment(data.createdDate).format('MM/DD/YYYY hh:mm')}
              <div
                className="user-name"
                onClick={(event) => openCard(event)}
                data-pr-tooltip={data.createdBy}
                data-pr-position="top"
              >
                by {data.createdBy}
              </div>
            </div>
          </CardText>
        </CardFooter>
      </Card>
    );
  };

  render() {
    const { sortedStudiesData, selectedClient, redirect } = this.props;

    return (
      <StyledCards>
        <ConfirmDialog />
        <CardsMenu>
          <ManagementButton
            action={this.confirmCreateStudy}
            backIcon="pi-chart-line"
            border
            btnText="New study"
            id="new-study-button"
            disabled={
              (this.props.clientsData.length > 0 && !this.props.selectedClient.clientGuid) ||
              !this.props.riskProxies.length
            }
          />
          {Boolean(this.state.clientOptions.length > 1) && (
            <Dropdown
              value={this.props.selectedClient.clientGuid}
              options={this.state.clientOptions}
              optionLabel="name"
              optionValue="clientGuid"
              itemTemplate={this.clientItemTemplate}
              placeholder="Select a Company"
              filterBy="name"
              filter={this.props.clientsData.length > 5}
              showFilterClear
            />
          )}
          {Boolean(this.props.okta?.email?.includes('@nepc.com')) && (
            <>
              <ActionButtons>
                <NavLink
                  target="_blank"
                  className="mix-manager-btn"
                  href={getMixManagerUrl(selectedClient.clientGuid)}
                  id="mix-manager-link"
                >
                  <ManagementButton border onlyIcon btnText="Mix Manager" />
                </NavLink>
                <ManagementButton
                  border
                  onlyIcon
                  backIcon="pi-cog"
                  action={() => redirect('/settings')}
                  id="open-settings-cards"
                />
              </ActionButtons>
            </>
          )}
        </CardsMenu>

        {Boolean(sortedStudiesData.length) && (
          <div className="cards">
            <Tooltip target=".title-text" position="top" />
            <Tooltip target=".user-name" position="top" />
            {sortedStudiesData.map((item) => {
              return this.card(item);
            })}
          </div>
        )}
      </StyledCards>
    );
  }
}

Cards.propTypes = {
  clientsData: PropTypes.array,
  cloneStudy: PropTypes.func,
  createNewStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  dispatchSelectedClient: PropTypes.func,
  loadAssumptions: PropTypes.func,
  loadClients: PropTypes.func,
  loadRiskProxies: PropTypes.func,
  loadStudiesData: PropTypes.func,
  okta: PropTypes.object,
  redirect: PropTypes.func,
  riskProxies: PropTypes.array,
  assumptions: PropTypes.array,
  selectedClient: PropTypes.object,
  selectStudy: PropTypes.func,
  sortedStudiesData: PropTypes.array,
};

const sortStudiesData = (studiesData) => studiesData.sort((a, b) => moment(a.lastUpdate).isBefore(b.lastUpdate));

const mapStateToProps = (state) => ({
  assumptions: state.resources.assumptions.data || [],
  clientsData: state.resources.clients.data || [],
  okta: state.ui.okta || {},
  riskProxies: state.resources.risk_proxies.data || [],
  selectedClient: state.ui.selectedClient || {},
  sortedStudiesData: sortStudiesData(state.resources.studies_data.data || []),
});

export default connect(mapStateToProps, {
  cloneStudy,
  createNewStudy,
  deleteStudy,
  dispatchSelectedClient,
  loadAssumptions,
  loadClients,
  loadRiskProxies,
  loadStudiesData,
  redirect,
  selectStudy,
})(Cards);
