import axios from 'axios';

import Constants from './Constants';
import { getHTTPHeaders } from './common';

const MaintenanceService = () => {
  const MAINTENANCE_URL = Constants.OKTA.URL;

  const saveMaintenance = (enabled) => axios.post(`${MAINTENANCE_URL}/maintenance`, enabled, getHTTPHeaders());

  const updateMaintenanceImage = (newImage) => {
    const fd = new FormData();
    fd.append('file', newImage, newImage.name);
    fd.append('fileName', newImage.name);
    return axios.post(`${MAINTENANCE_URL}/maintenance/img`, fd, getHTTPHeaders());
  };

  const updateMaintenanceImageTmp = (newImage) => {
    const fd = new FormData();
    fd.append('file', newImage, newImage.name);
    fd.append('fileName', newImage.name);
    return axios.post(`${MAINTENANCE_URL}/maintenance/imgTmp?${Math.random()}`, fd, getHTTPHeaders());
  };

  const getImageUrl = () => {
    return axios.get(`${MAINTENANCE_URL}/maintenance/imgUrl?${Math.random()}`);
  };

  const resetMaintenanceImageTmp = () => {
    return axios.post(`${MAINTENANCE_URL}/maintenance/imgTmpReset?${Math.random()}`, getHTTPHeaders());
  };

  const checkIsUnderMaintence = () => axios.get(`${MAINTENANCE_URL}/maintenance/maintenanceStatus`);

  return {
    saveMaintenance,
    updateMaintenanceImage,
    updateMaintenanceImageTmp,
    resetMaintenanceImageTmp,
    getImageUrl,
    checkIsUnderMaintence,
  };
};

export default MaintenanceService;
