import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import _ from 'lodash';

import { loadLatest, acceptTermsAndConditions } from './actions';
import { redirect } from '../../actions';
import { StyledTermsAndConditions, TermsText } from './styles';
import ManagementButton from '../ManagementButton';

export const TermsAndConditions = (props) => {
  if (!props.terms) {
    props.loadLatest();
  }

  const handleAccept = () => {
    props.acceptTermsAndConditions();
  };

  const handleDecline = () => {
    props.redirect('/');
  };

  const renderControls = () => {
    if (props.accepted || props.loading || !props.terms || !props.user) {
      return null;
    }

    return (
      <div>
        <p className="text-justify">I have read and accept NEPC’s Terms of Use written above.</p>

        <p>
          <Button onClick={handleAccept} style={{ marginRight: '1em' }}>
            Accept
          </Button>
          <Button onClick={handleDecline}>Decline</Button>
        </p>
      </div>
    );
  };

  const html = props.terms;
  return (
    <StyledTermsAndConditions>
      <ManagementButton backIcon="pi pi-angle-left" action={() => props.redirect('/')} circle />
      <TermsText>
        <div className="quill ql-snow">
          <span className="ql-editor ql-container" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        {renderControls()}
        <p className="text-justify">Please contact your NEPC Consultant should you have any questions or concerns.</p>
      </TermsText>
    </StyledTermsAndConditions>
  );
};

TermsAndConditions.propTypes = {
  loadLatest: PropTypes.func.isRequired,
  acceptTermsAndConditions: PropTypes.func.isRequired,
  accepted: PropTypes.bool,
  redirect: PropTypes.func.isRequired,

  terms: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const latest = state.terms.latest;
  const { loading } = latest;
  const terms = _.get(latest, 'data.terms');
  const user = state.ui.okta;
  return {
    accepted: state.terms.accepted,
    terms,
    loading,
    user,
  };
};

export default connect(mapStateToProps, { redirect, acceptTermsAndConditions, loadLatest })(TermsAndConditions);
