import styled from 'styled-components';

export const StyledCashFlows = styled.div`
  padding-top: 20px;
  .sm-visible {
    display: none;
  }

  .p-inputnumber-input {
    width: 50%;
  }

  .cash-flows-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 15px 4px 24px;
    margin: 0 15px 8px 0;
  }

  .cash-flows-table {
    .cashflow-description-header {
      justify-content: space-between;
    }

    tr.fixed i {
      visibility: hidden;
    }

    tr > th {
      color: #000000;
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      padding-right: 5px;
      background-color: #c9d4de;
      max-height: 35px;
      border: 1px solid #b6b6b6;
      .p-column-header-content,
      .p-column-title {
        text-align: center;
        width: 100%;
      }
      .p-column-header-content {
        display: flex;
        justify-content: center;
      }
    }
    th.cash-flows-description .cashflow-description-header {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    tr.p-highlight,
    tr:hover:not(.p-datatable-row-expansion) {
      background: #dbe7f2;
      color: #495057;
      .p-button {
        background: transparent;
        transition: color 0.5s;
        color: #16709e;
      }
    }

    tr :first-child:not(.p-button):not(input) {
      border-top-width: 1px;
      border-right-width: 0px;
      border-bottom-width: 1px;
      border-left-width: 0px;
      i:not(.pi-trash) {
        font-size: 0.8rem;
      }
    }

    tr :nth-child(2) {
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 0;
    }
    tbody,
    tfoot {
      .desc-item {
        display: flex;
        font-weight: bold;
        font-size: 16px;
        .p-button {
          background: transparent;
          margin-right: 10px;
        }
      }
      .p-datatable-row-expansion {
        td {
          padding: 0;
        }
        .p-datatable-thead {
          display: none;
        }
      }
      .read-only {
        background-color: rgb(242, 242, 242);
      }
      .cashFooter {
        width: 100%;
        :first-child {
          width: 35%;
          padding: 0 0.3rem 0 1.5rem;
        }
        * {
          width: 10.8%;
        }
      }
    }
    .p-datatable-tfoot {
      font-weight: bold;
    }
    td {
      color: #002060;
      border: 1px solid #b6b6b6;
    }
    .p-datatable-tbody td {
      font-weight: 600;
    }
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .desc-child {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin: 0px 10px;
    width: fill-available;
    .p-inputtext {
      width: inherit;
    }
  }

  .cash-flows-subdescription,
  .cash-flows-description {
    text-align: left;
    width: 32%;
    .p-inputtext {
      text-align: left;
    }
  }

  .read-only,
  .year0-cash-flows,
  .year1-cash-flows,
  .year2-cash-flows,
  .year3-cash-flows,
  .year4-cash-flows,
  .year5-cash-flows {
    width: 10.8%;
  }

  .parent-row {
    padding-right: 3px;
  }

  .p-datatable-tbody {
    color: #002060;
    font-size: 14px;
    max-width: 73px;
    line-height: 32px;
    text-align: right;
    padding: 0 0.3rem 0 1.5rem;
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40px;
    @media screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  .budget-table {
    thead {
      display: none;
    }
    tbody {
      td {
        :not(.read-only) {
          padding-right: 1px;
          border: 1px solid #b6b6b6;
        }
        &.read-only div {
          background-color: rgb(242, 242, 242);
        }
      }
    }
  }

  tfoot {
    background-color: rgb(242, 242, 242);
    td {
      padding-right: 3px;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }

  .p-inputtext {
    text-align: right;
    color: #002060;
    background: transparent;
    padding: 0rem 0.2rem;
    font-size: 14px;
    font-weight: 600;
    border: 0px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #6ed0f7;
    border-color: #6ed0f7;
  }

  p {
    line-height: 1.5;
    margin: 0 0 1rem 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .flag {
    vertical-align: middle;
  }

  span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
  }
`;

export const GreyBackgroundCell = styled.div`
  background-color: rgb(242, 242, 242);
  text-align: center;
  padding-right: 3px;
  font-weight: 600;
  &.parent-row {
    font-size: 16px;
  }
  &.child-row {
    font-size: 14px;
  }
`;
