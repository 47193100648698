import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Chart } from 'react-google-charts';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';

import DefaultBox from '../DefaultBox';
import NoDataAvailable from '../NoDataAvailable';
import SquareFlag from '../flag/SquareFlag';
import {
  StyledAssetAllocation,
  DescriptionBody,
  CellBody,
  ChartArea,
  AssetAllocationHeader,
} from './StyledAssetAllocation';
import { dispatchToExportStudy, dispatchSelectedAllocatedByValue } from '../../actions';

const AssetAllocation = (props) => {
  const { selectedAllocatedByValue } = props;
  const PREFIX = 'prefix-'; // used to force the dynamic array type as string
  const TOTAL_PERC = 'Total %';
  const CHART_PRECISION = 2;
  const TABLE_PRECISION = 2;

  const [aambs, setAambs] = useState([]);
  const [calculatedRows, setCalculatedRows] = useState([]);
  const [chart, setChart] = useState({ values: [], color: [] });
  const [percentageByMix, setPercentageByMix] = useState({});
  const [totalValuesByAAMBInPerc, setTotalValuesByAAMBInPerc] = useState({});

  const getBaseData = () => {
    let _aambs = [];
    let _percentageByMix = {};
    props.assetAllocation.forEach((mix) => {
      const aamb = {
        aambPk: mix.aambPk,
        name: mix.aambName,
        color: mix.colorAssignment,
      };
      // aamb List
      if (!_aambs.find((item) => item.aambPk === mix.aambPk)) {
        _aambs = [..._aambs, aamb];
      }

      // mix with AAMBs
      const mixKey = mix.mixName + '#' + mix.mixPk;
      if (!_percentageByMix[mixKey]) {
        _percentageByMix = { ..._percentageByMix, [mixKey]: {} };
      }
      let mixPerc = _percentageByMix[mixKey];
      const existentAAMBPerc = mixPerc[mix.aambPk] ? parseFloat(mixPerc[mix.aambPk]) : 0;
      mixPerc = { ...mixPerc, [mix.aambPk]: parseFloat(mix.allocationPercent) + existentAAMBPerc };
      _percentageByMix = { ..._percentageByMix, [mixKey]: { ...mixPerc } };
    });
    setAambs([..._aambs]);
    setPercentageByMix({ ..._percentageByMix });
  };

  useEffect(() => {
    if (props.assetAllocation.length) {
      getBaseData();
    }
  }, [props.assetAllocation]);

  const getChartValuesWithoutTotal = () => {
    const _totalValuesByAAMBInDollar = {};
    let chartHeaders = [];
    let chartValuesWithoutTotal = [];
    let chartColors = [];
    aambs.forEach((aamb) => {
      chartHeaders = [aamb.name, ...chartHeaders];
      chartColors = [aamb.color, ...chartColors];
    });
    chartHeaders = ['Asset Pool', ...chartHeaders];
    chartValuesWithoutTotal = [...chartValuesWithoutTotal, chartHeaders];

    // Items values
    props.assetPools.forEach((item) => {
      const mixKey = item.mixName + '#' + item.mixId;
      const mixPerc = percentageByMix[mixKey];
      if (mixPerc) {
        let itemValues = [];
        aambs.forEach((aamb) => {
          const aambValue = mixPerc[aamb.aambPk];
          if (aambValue && aambValue > 0) {
            const aambPerc = Number(mixPerc[aamb.aambPk]);
            itemValues = [aambPerc, ...itemValues];

            // total value by AAMB
            let ammount = _totalValuesByAAMBInDollar[aamb.aambPk] ? _totalValuesByAAMBInDollar[aamb.aambPk] : 0;
            ammount += item.aum * (aambPerc / 100);
            _totalValuesByAAMBInDollar[aamb.aambPk] = ammount;
          } else {
            itemValues = [0, ...itemValues];
          }
        });
        const parsedValues = itemValues.map((item) => Number(item.toFixed(CHART_PRECISION)));
        itemValues = [item.description, ...parsedValues];
        chartValuesWithoutTotal = [...chartValuesWithoutTotal, itemValues];
      }
    });
    return { chartValuesWithoutTotal, chartColors, _totalValuesByAAMBInDollar };
  };

  const getValuesMain = () => {
    const { chartValuesWithoutTotal, chartColors, _totalValuesByAAMBInDollar } = getChartValuesWithoutTotal();
    const totalDollarIsEmpty = Object.keys(_totalValuesByAAMBInDollar).length === 0;
    let _totalValuesByAAMBInPerc = {};
    if (!totalDollarIsEmpty) {
      _totalValuesByAAMBInPerc = getTotalValuesByAAMBInPerc(_totalValuesByAAMBInDollar);
    }
    const totalPercIsEmpty = Object.keys(_totalValuesByAAMBInPerc).length === 0;
    let totalColumnForChart = {};
    if (!totalPercIsEmpty) {
      totalColumnForChart = getTotalColumnForChart(_totalValuesByAAMBInPerc);
    }
    setTotalValuesByAAMBInPerc({ ..._totalValuesByAAMBInPerc });
    setChart({ values: [...chartValuesWithoutTotal, totalColumnForChart], color: chartColors });
  };

  useEffect(() => {
    const percentageByMixIsEmpty = Object.keys(percentageByMix).length === 0;
    if (aambs.length && !percentageByMixIsEmpty) {
      getValuesMain();
    }
  }, [aambs, percentageByMix, props.assetPools]);

  const getTotalColumnForChart = (_totalValuesByAAMBInPerc) => {
    let totalColumn = [];
    aambs.forEach((aamb) => {
      const ammount = _totalValuesByAAMBInPerc[aamb.aambPk]
        ? Number(_totalValuesByAAMBInPerc[aamb.aambPk]).toFixed(CHART_PRECISION)
        : 0;
      totalColumn = [ammount, ...totalColumn];
    });
    return [TOTAL_PERC, ...totalColumn];
  };

  const getTotalValuesByAAMBInPerc = (_totalValuesByAAMBInDollar) => {
    let _totalValuesByAAMBInPerc = {};
    const totalAUM = _.sumBy(props.assetPools, 'aum');
    let totalPerc = 0;
    aambs.forEach((aamb) => {
      if (_totalValuesByAAMBInDollar[aamb.aambPk]) {
        const value = ((_totalValuesByAAMBInDollar[aamb.aambPk] * 100) / totalAUM).toFixed(TABLE_PRECISION);
        if (totalPerc + parseFloat(value) > 100.0) {
          _totalValuesByAAMBInPerc = {
            ..._totalValuesByAAMBInPerc,
            [aamb.aambPk]: (100 - totalPerc).toFixed(TABLE_PRECISION),
          };
        } else {
          _totalValuesByAAMBInPerc = { ..._totalValuesByAAMBInPerc, [aamb.aambPk]: value };
        }
        totalPerc += parseFloat(value);
      }
    });
    return { ..._totalValuesByAAMBInPerc };
  };

  useEffect(() => {
    if (!selectedAllocatedByValue) props.dispatchSelectedAllocatedByValue('AAMB');
  }, [selectedAllocatedByValue]);

  useEffect(() => {
    const columns = props.assetPools.map((i) => {
      return { id: PREFIX + i.assetPoolId, description: i.description };
    });
    props.dispatchToExportStudy({
      assetAllocation: {
        columns: columns,
        rows: calculatedRows,
      },
    });
  }, [calculatedRows, props.assetPools]);

  const getChartOptions = () => {
    return {
      colors: chart.color,
      title: props.mainTitle,
      legend: { position: 'none' },
      chartArea: { width: '80%' },
      isStacked: true,
      hAxis: {
        title: props.hTitle,
        minValue: 1,
      },
      vAxis: {
        title: props.vTitle,
        viewWindowMode: 'explicit',
        viewWindow: {
          max: 102,
          min: 0,
        },
      },
      backgroundColor: {
        fill: 'transparent',
        opacity: 100,
      },
    };
  };

  const getPrintChartOptions = () => {
    return {
      width: 960,
      colors: chart.color,
      title: props.mainTitle,
      legend: { position: 'none' },
      chartArea: { width: '80%' },
      isStacked: true,
      hAxis: {
        title: props.hTitle,
        minValue: 1,
      },
      vAxis: {
        title: props.vTitle,
        viewWindowMode: 'explicit',
        viewWindow: {
          max: 102,
          min: 0,
        },
      },
      backgroundColor: {
        fill: 'transparent',
        opacity: 100,
      },
    };
  };

  const calculateRows = () => {
    let _calculatedRows = [];
    aambs.forEach((aamb) => {
      let assetValues;
      props.assetPools.forEach((ap) => {
        const mixPerc = percentageByMix[ap.mixName + '#' + ap.mixId];
        assetValues = {
          ...assetValues,
          [PREFIX + ap.assetPoolId]:
            mixPerc && mixPerc[aamb.aambPk] > 0 ? Number(mixPerc[aamb.aambPk]).toFixed(TABLE_PRECISION) : 0,
        };
      });
      assetValues = {
        ...assetValues,
        total: totalValuesByAAMBInPerc[aamb.aambPk],
      };

      if (totalValuesByAAMBInPerc[aamb.aambPk]) {
        _calculatedRows = [
          ..._calculatedRows,
          {
            key: aamb.aambPk,
            color: aamb.color,
            description: aamb.name,
            ...assetValues,
          },
        ];
      }
    });
    setCalculatedRows([..._calculatedRows]);
  };

  useEffect(() => {
    const totalPercIsEmpty = Object.keys(totalValuesByAAMBInPerc).length === 0;
    const percByMixIsEmpty = Object.keys(percentageByMix).length === 0;
    if (aambs.length && !totalPercIsEmpty && !percByMixIsEmpty && props.assetPools.length) {
      calculateRows();
    }
  }, [aambs, totalValuesByAAMBInPerc, percentageByMix, props.assetPools]);

  const descriptionBody = (rowData) => {
    return (
      <DescriptionBody className="description-body">
        <SquareFlag color={rowData.color} />
        <span className="p-column-title">{rowData.description}</span>
      </DescriptionBody>
    );
  };

  const cellBody = (rowData, colBodyOptions) => {
    return <CellBody>{rowData[colBodyOptions.field] > 0 ? `${rowData[colBodyOptions.field]}%` : ''}</CellBody>;
  };

  const headerBody = (description) => {
    const items = description.split('#');
    return (
      <>
        <p>{items[0]}</p>
        <p>{items[1]}</p>
      </>
    );
  };

  const calculateColumns = () => {
    const columns = props.assetPools.map((col) => {
      return { id: col.assetPoolId, name: col.description, description: col.description + `#(${col.mixName})` };
    });
    const assetPoolsItems = columns.map((col) => {
      return (
        <Column
          field={PREFIX + col.id}
          header={() => headerBody(col.description)}
          body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
          key={(rowData) => rowData.key}
        />
      );
    });
    return [
      <Column
        className="description"
        field="description"
        key="description"
        header="Asset Class"
        body={(rowData) => descriptionBody(rowData)}
      />,
      assetPoolsItems,
      <Column
        className="total"
        field="total"
        key="total"
        header="Total %"
        body={(rowData, colBodyOptions) => cellBody(rowData, colBodyOptions)}
      />,
    ];
  };

  const allocatedByOptions = [
    { name: 'Asset Class Lookthrough', code: 'ASSET_CLASS_LOOKTHROUGH' },
    { name: 'Beta Group', code: 'BETA_GROUP' },
    { name: 'AAMB', code: 'AAMB' },
    { name: 'Liquidity', code: 'LIQUIDITY' },
  ];

  return (
    <DefaultBox className="AssetAllocation" fullBorder={false}>
      <StyledAssetAllocation>
        <AssetAllocationHeader>
          <h2>Asset Allocation & Liquidity</h2>
          <div className="header-inputs">
            <div className="dropdown">
              <Dropdown
                value={selectedAllocatedByValue}
                options={allocatedByOptions}
                optionLabel="name"
                optionValue="code"
                onChange={(event) => props.dispatchSelectedAllocatedByValue(event.value)}
                placeholder="Select the Asset Allocation"
              />
            </div>
          </div>
        </AssetAllocationHeader>
        {Boolean(chart.values.length > 2) && (
          <ChartArea>
            {Boolean(!props.printing) && (
              <Chart
                height={'250px'}
                chartType="ColumnChart"
                className="asset-allocation-graph"
                loader={<div>Loading Chart</div>}
                data={chart.values}
                options={getChartOptions()}
              />
            )}
            {Boolean(props.printing) && (
              <Chart
                height={'250px'}
                chartType="ColumnChart"
                className="asset-allocation-graph"
                loader={<div>Loading Chart</div>}
                data={chart.values}
                options={getPrintChartOptions()}
              />
            )}
            <div className="asset-allocation-table">
              <DataTable className="asset-table" value={calculatedRows} stripedRows responsiveLayout="scroll">
                {calculateColumns()}
              </DataTable>
              {Boolean(selectedAllocatedByValue == 'LIQUIDITY') && (
                <div className="liquidity-note">
                  <i>Note: Estimated liquidity, actual liquidity will be dictated by underlying managers terms.</i>
                </div>
              )}
            </div>
          </ChartArea>
        )}
        {Boolean(chart.values.length < 3) && <NoDataAvailable />}
      </StyledAssetAllocation>
    </DefaultBox>
  );
};

AssetAllocation.propTypes = {
  assetAllocation: PropTypes.array,
  assetPools: PropTypes.array,
  dispatchSelectedAllocatedByValue: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  displaySingleColumnLayout: PropTypes.bool,
  hTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  printing: PropTypes.bool,
  selectedAllocatedByValue: PropTypes.string,
  vTitle: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    assetAllocation: state.resources.asset_allocation.data || [],
    assetPools: state.ui?.selectedStudy?.assetPools || [],
    displaySingleColumnLayout: state.ui?.displaySingleColumnLayout || false,
    printing: state.ui?.printing || false,
    selectedAllocatedByValue: state.ui?.selectedAllocatedByValue || '',
  };
};

export default connect(mapStateToProps, {
  dispatchSelectedAllocatedByValue,
  dispatchToExportStudy,
})(AssetAllocation);
