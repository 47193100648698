import React from 'react';
import { Container, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

import styled from 'styled-components';

const StyledFooter = styled.div`
  .footer {
    align-self: flex-end;
    min-width: 100%;

    background-color: #333;
    padding: 0.25em;
    z-index: 9999;

    .nav-link,
    .buildId {
      color: white;
      font-size: 1.125rem;
      display: inline-block;
      padding: 0.5rem;
    }

    .buildId {
      float: right;
      font-size: small;
    }

    .pipe {
      color: #c9d4de;
      margin: 0 7px;
      position: relative;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container className="footer">
        <NavLink href="http://www.nepc.com" id="nepc-link">
          www.nepc.com
        </NavLink>
        <span className="pipe">|</span>
        <NavLink href="https://nepc.com/institutional/privacy-statement/" id="privacy-link">
          Privacy Statement
        </NavLink>
        <span className="pipe">|</span>
        <RouterNavLink className="nav-link" to="/terms" id="terms-link">
          Terms and Conditions
        </RouterNavLink>
        <span className="pipe">|</span>
        <NavLink href="https://nepc.com/institutional/insights/" id="blog-link">
          Blog
        </NavLink>
        <span className="pipe">|</span>
        <NavLink href="https://clienthub.nepc.com" id="clhb-link">
          ClientHub
        </NavLink>
        {/* <span className="buildId">Build: {Constants.BUILD_ID}</span> */}
      </Container>
    </StyledFooter>
  );
};

export default Footer;
