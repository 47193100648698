import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import Chart from 'react-google-charts';

import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable';

import { Button, UncontrolledTooltip } from 'reactstrap';

import {
  calcEconomicScenarios,
  DCOH_CHILD_IDX,
  FORECASTED_AUM_CHILD_IDX,
  INV_GAIN_LOSS_CHILD_IDX,
  INV_RETURN_CHILD_IDX,
} from './CalcEconomicScenarios';
import DefaultBox from '../DefaultBox';
import ManagementButton from '../ManagementButton';
import StyledEconomicScenarios from './StyledEconomicScenarios';

import {
  calcRiskReturn,
  dispatchCheckStudyValidity,
  dispatchIsRelativeToBudget,
  dispatchToExportStudy,
  storeAUMAndReturn,
  storeEconomicScenarios,
} from '../../actions';

import Constants from '../../services/Constants';

const EconomicScenarios = (props) => {
  const { calculatedEconomicScenarios, isRelativeToBudget, measureScale, printing, selectedStudy } = props;

  const { decimalPrecision } = selectedStudy.study;

  const addMeasureScale = (description, childDescription, measure, measureScaleSymbol) =>
    measure === '$'
      ? `${description} (${childDescription ? childDescription + ' - ' : ''}${measure}${measureScaleSymbol})`
      : `${description} (${childDescription ? childDescription + ' - ' : ''}${measure})`;
  const removeMeasureScale = (label) =>
    label
      .replace(/\(.*[$%][MB]?\)/, '')
      .replace(/\(.*\$000\)/, '')
      .replace(/\(.*Days\)/, '');

  const parseNodes = (_selectedCalcValue) =>
    calculatedEconomicScenarios.map((item) => {
      const currentChild =
        item.children.find((child) => child.key == _selectedCalcValue) ||
        item.children.find((child) => child.description.includes('AUM')) ||
        item.children[0];
      return {
        ...item,
        description: addMeasureScale(
          item.description,
          currentChild.description,
          currentChild.measure,
          measureScale.measureSymbol
        ),
        measure: currentChild.measure,
        year0: currentChild.year0,
        year1: currentChild.year1,
        year2: currentChild.year2,
        year3: currentChild.year3,
        year4: currentChild.year4,
        year5: currentChild.year5,
        children: item.children.map((child) => ({
          ...child,
          description: addMeasureScale(child.description, '', child.measure, measureScale.measureSymbol),
        })),
      };
    });

  const _nodes = parseNodes();
  const scenarioOptions = _nodes.map((node) => ({
    name: removeMeasureScale(node.description),
    code: node.key,
  }));

  // TODO Store and load selectedCalcValue from the API (previous selection)
  const calcValuesOptions = _nodes[0]
    ? _nodes[0].children.map((child) => ({
        name: child.description,
        code: child.key,
      }))
    : [];

  const [nodes, setNodes] = useState(_nodes);
  const [selectedCalcValue, setSelectedCalcValue] = useState(
    calcValuesOptions.find((child) => child.name.includes('AUM'))?.code
  );
  const [selectedNodeKey, setSelectedNodeKey] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [isPrinting, setIsPrinting] = useState(printing);
  const [hasTargetLine1, setHasTargetLine1] = useState(false);
  const [hasTargetLine2, setHasTargetLine2] = useState(false);

  useEffect(() => {
    setSelectedNodes(nodes.filter((node) => selectedScenarios.find((ss) => ss.code == node.key)));
  }, [nodes, selectedScenarios]);

  useEffect(() => {
    let _selectedScenarios = scenarioOptions.filter((scenario) =>
      selectedStudy.study.selectedScenarios?.includes(Number(scenario.code))
    );
    if (scenarioOptions.length && (!_selectedScenarios || !_selectedScenarios.length)) {
      _selectedScenarios = [
        scenarioOptions.find((scenario) => scenario.name.includes('Base Case')),
        scenarioOptions.find((scenario) => scenario.name.includes('Depression')),
        scenarioOptions.find((scenario) => scenario.name.includes('Expansion')),
      ];
    }
    setSelectedNodes(nodes.filter((node) => _selectedScenarios.find((ss) => ss.code == node.key)));
    setSelectedScenarios(_selectedScenarios);
    if (isNaN(selectedCalcValue) && calcValuesOptions.length) {
      setSelectedCalcValue(calcValuesOptions.find((child) => child.name.includes('AUM')).code);
    }
  }, [selectedStudy.study]);

  useEffect(() => {
    const baseCase = nodes.find((node) => node.description.includes('Base'));
    if (baseCase) {
      const forecastedAUM = baseCase.children.find((child) => child.description.includes('AUM'));
      const invReturn = baseCase.children.find((child) => child.description.includes('Return'));
      if (!isNaN(forecastedAUM.year0)) {
        props.storeAUMAndReturn({
          esBaseCaseAumYear0: forecastedAUM.year0,
          esBaseCaseAumYear1: forecastedAUM.year1,
          esBaseCaseAumYear2: forecastedAUM.year2,
          esBaseCaseAumYear3: forecastedAUM.year3,
          esBaseCaseAumYear4: forecastedAUM.year4,
          esBaseCaseAumYear5: forecastedAUM.year5,
          esBaseCaseProjectedReturnYear0: invReturn.year0,
          esBaseCaseProjectedReturnYear1: invReturn.year1,
          esBaseCaseProjectedReturnYear2: invReturn.year2,
          esBaseCaseProjectedReturnYear3: invReturn.year3,
          esBaseCaseProjectedReturnYear4: invReturn.year4,
          esBaseCaseProjectedReturnYear5: invReturn.year5,
        });
      }
    }
  }, [nodes]);

  useEffect(() => {
    setNodes(parseNodes(selectedCalcValue));
    props.dispatchToExportStudy({
      calculatedEconomicScenarios,
    });
  }, [
    isRelativeToBudget,
    measureScale,
    selectedStudy.calculatedCashFlows?.partialSum,
    selectedCalcValue,
    selectedStudy.economicScenarios,
    selectedStudy.financialMetrics.dailyExpenseFactor,
  ]);

  useEffect(() => {
    setIsPrinting(printing);
  }, [printing]);

  const prettyNumber = (rowData, colBodyOptions) => {
    const value = rowData[colBodyOptions.field];
    if (isNaN(value)) return '';
    if (rowData['measure'] === '%') {
      return (
        <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
          {value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + '%'}
        </div>
      );
    }
    if (rowData['measure'] === 'Days') {
      return (
        <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
          {value.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
        </div>
      );
    }
    return (
      <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
        {value.toLocaleString('en-US', {
          minimumFractionDigits: decimalPrecision,
          maximumFractionDigits: decimalPrecision,
        })}
      </div>
    );
  };

  const handleCalcRiskReturn = () => {
    const { studyIsValid } = props.dispatchCheckStudyValidity();
    const [isValid, error] = studyIsValid;
    if (error) return NotificationManager.error(error);
    if (isValid) {
      props.calcRiskReturn();
    }
  };

  const collapseAllKeys = () => setExpandedKeys({});

  const expandAllKeys = () => {
    const _expandedKeys = nodes.reduce((acc, node) => ({ ...acc, [node.key]: true }), {});
    setExpandedKeys(_expandedKeys);
  };

  const scenariosDescriptionHeader = (
    <div className="scenarios-description-header">
      <div>
        <span>Scenario</span>
      </div>
      <div>
        <Button id="collapse-scenario-all-button" color="link" onClick={() => collapseAllKeys()}>
          <i className="pi nepc-pi-collapse-all"></i>
        </Button>
        <Button id="expand-scenario-all-button" color="link" onClick={() => expandAllKeys()}>
          <i className="pi nepc-pi-expand-all"></i>
        </Button>
        <UncontrolledTooltip placement="top" trigger="hover" target="collapse-scenario-all-button">
          Collapse all
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" trigger="hover" target="expand-scenario-all-button">
          Expand all
        </UncontrolledTooltip>
      </div>
    </div>
  );

  const economicScenariosDescriptionBody = (rowData, colBodyOptions) => (
    <div className={`${rowData.children ? 'parent-row desc-item' : 'child-row desc-item'}`}>{rowData.description}</div>
  );

  const getTargetLineArray = (label, value) => [label, ...Array(6).fill(value)];

  const getGraphData = () => {
    const {
      columnHead0Year,
      columnHead1Year,
      columnHead2Year,
      columnHead3Year,
      columnHead4Year,
      columnHead5Year,
      targetDCOH,
      targetDCOH2,
      targetForecastedAum,
      targetForecastedAum2,
      targetInvGainLoss,
      targetInvGainLoss2,
      targetInvReturn,
      targetInvReturn2,
      targetLabel,
      targetLabel2,
    } = selectedStudy.study;
    let graphData = [
      [
        'Scenario',
        columnHead0Year,
        columnHead1Year,
        columnHead2Year,
        columnHead3Year,
        columnHead4Year,
        columnHead5Year,
      ],
    ];

    if (!isRelativeToBudget) {
      switch (selectedCalcValue) {
        case FORECASTED_AUM_CHILD_IDX:
          if (targetForecastedAum) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetForecastedAum)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetForecastedAum2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetForecastedAum2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case DCOH_CHILD_IDX:
          if (targetDCOH) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetDCOH)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetDCOH2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetDCOH2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case INV_GAIN_LOSS_CHILD_IDX:
          if (targetInvGainLoss) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetInvGainLoss)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetInvGainLoss2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetInvGainLoss2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case INV_RETURN_CHILD_IDX:
          if (targetInvReturn) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetInvReturn)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetInvReturn2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetInvReturn2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        default:
          hasTargetLine1 && setHasTargetLine1(false);
          hasTargetLine2 && setHasTargetLine2(false);
      }
    } else {
      hasTargetLine1 && setHasTargetLine1(false);
      hasTargetLine2 && setHasTargetLine2(false);
    }

    graphData = [
      ...graphData,
      ...selectedNodes.map((node) => [
        removeMeasureScale(node.description),
        node.year0,
        node.year1,
        node.year2,
        node.year3,
        node.year4,
        node.year5,
      ]),
    ];

    return graphData[0].map((_, colIndex) => graphData.map((row) => row[colIndex]));
  };

  const handleSelectedScenarios = (value) => {
    if (value.length > 0) {
      setSelectedScenarios(value);
      props.storeEconomicScenarios({
        selectedScenarios: value.map((scenario) => Number(scenario.code)),
      });
    } else {
      NotificationManager.error(
        'Please, you must choose at least One Economic Scenario',
        'Select 1 or more scenarios',
        Constants.POP_UP_TIME
      );
    }
  };

  const handleCalcValueChange = (value) => {
    setSelectedCalcValue(value);
  };

  const getVAxisFormat = (measure) => {
    if (measure === '%') return "#,###'%'";
    if (measure === '$') return '$#,###';
    return '';
  };

  const getTargetLineStyling = () => {
    const hasBothTargetLines = hasTargetLine1 & hasTargetLine2;
    const hasOnlyTargetLine1 = hasTargetLine1 & !hasTargetLine2;
    const hasOnlyTargetLine2 = !hasTargetLine1 & hasTargetLine2;

    const styleOfTargetLine1 = { color: 'gray', lineDashStyle: [14, 4] };
    const styleOfTargetLine2 = { color: 'gray', lineDashStyle: [3, 4] };

    if (hasBothTargetLines) {
      return { 0: styleOfTargetLine1, 1: styleOfTargetLine2 };
    }

    if (hasOnlyTargetLine1) {
      return { 0: styleOfTargetLine1 };
    }

    if (hasOnlyTargetLine2) {
      return { 0: styleOfTargetLine2 };
    }

    return null;
  };

  return (
    <DefaultBox>
      <StyledEconomicScenarios id="economic-scenarios">
        <div className="economic-scenarios-header">
          <div className="main-header">
            <h2>Overview of Scenarios - Five Year Projections</h2>
            {Boolean(
              selectedNodes.length && selectedStudy.study && calcValuesOptions.length && scenarioOptions.length
            ) && (
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="binary-relative"
                  checked={isRelativeToBudget}
                  onChange={(event) => props.dispatchIsRelativeToBudget(event.checked)}
                />
                <label htmlFor="binary-relative">Relative to budget</label>
              </div>
            )}
          </div>
          {Boolean(calcValuesOptions.length && scenarioOptions.length) && (
            <div className="header-inputs">
              <div className="dropdown">
                <Dropdown
                  value={selectedCalcValue}
                  options={calcValuesOptions}
                  optionLabel="name"
                  optionValue="code"
                  onChange={(event) => handleCalcValueChange(event.value)}
                  placeholder="Select a calculated value"
                />
              </div>
              <div className="multiselect">
                <div className="card">
                  <MultiSelect
                    value={selectedScenarios}
                    options={scenarioOptions}
                    onChange={(event) => handleSelectedScenarios(event.value)}
                    optionLabel="name"
                    placeholder="Select a Scenario"
                    display="chip"
                    showSelectAll={false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {Boolean(selectedNodes.length && selectedStudy.study) && (
          <div className="economic-scenarios-graph">
            {Boolean(!isPrinting) && (
              <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={getGraphData()}
                className="es-graph"
                options={{
                  height: 400,
                  chartArea: { top: 30, right: 30, bottom: 80, left: 100 },
                  legend: 'bottom',
                  series: getTargetLineStyling(),
                  vAxis: {
                    format: getVAxisFormat(selectedNodes[0].measure),
                  },
                  backgroundColor: {
                    fill: 'transparent',
                    opacity: 100,
                  },
                  colors: ['#002060', '#16709E', '#6ED0F7', '#8CC94A', '#D6F28C', '#F2CC73', '#43505E', '#8596A8'],
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            )}
            {Boolean(isPrinting) && (
              <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={getGraphData()}
                options={{
                  width: 960,
                  height: 400,
                  chartArea: { top: 30, right: 30, bottom: 80, left: 100 },
                  legend: 'bottom',
                  vAxis: {
                    format: getVAxisFormat(selectedNodes[0].measure),
                  },
                  backgroundColor: {
                    fill: 'transparent',
                    opacity: 100,
                  },
                  colors: ['#002060', '#16709E', '#6ED0F7', '#8CC94A', '#D6F28C', '#F2CC73', '#43505E', '#8596A8'],
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            )}
          </div>
        )}
        {Boolean(selectedNodes.length && selectedStudy.study) && (
          <div className="economic-scenario-table">
            <TreeTable
              value={selectedNodes}
              className="economic-scenarios-table"
              selectionMode="single"
              selectionKeys={selectedNodeKey}
              onSelectionChange={(event) => setSelectedNodeKey(event.value)}
              expandedKeys={expandedKeys}
              onToggle={(event) => setExpandedKeys(event.value)}
            >
              <Column
                field="scenario"
                header={scenariosDescriptionHeader}
                key={({ key }) => `scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => economicScenariosDescriptionBody(rowData, colBodyOptions)}
                className="scenario"
                expander
                style={{ width: '360px' }}
              ></Column>
              <Column
                field="year0"
                header={selectedStudy.study.columnHead0Year}
                key={({ key }) => `year0-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year1"
                header={selectedStudy.study.columnHead1Year}
                key={({ key }) => `year1-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year2"
                header={selectedStudy.study.columnHead2Year}
                key={({ key }) => `year2-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year3"
                header={selectedStudy.study.columnHead3Year}
                key={({ key }) => `year3-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year4"
                header={selectedStudy.study.columnHead4Year}
                key={({ key }) => `year4-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year5"
                header={selectedStudy.study.columnHead5Year}
                key={({ key }) => `year5-scenario-economic-scenarios-${key.join('-')}`}
                body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
              ></Column>
            </TreeTable>
          </div>
        )}

        {!Boolean(
          selectedNodes.length && selectedStudy.study && calcValuesOptions.length && scenarioOptions.length
        ) && (
          <ManagementButton
            backIcon="pi-refresh"
            btnText="Calculate"
            action={handleCalcRiskReturn}
            id="calculate-economic-scenarios-button"
            border
          />
        )}
      </StyledEconomicScenarios>
    </DefaultBox>
  );
};

EconomicScenarios.propTypes = {
  calcRiskReturn: PropTypes.func,
  calculatedEconomicScenarios: PropTypes.array,
  dispatchCheckStudyValidity: PropTypes.func,
  dispatchIsRelativeToBudget: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  isRelativeToBudget: PropTypes.bool,
  measureScale: PropTypes.object,
  printing: PropTypes.bool,
  selectedStudy: PropTypes.object,
  storeAUMAndReturn: PropTypes.func,
  storeEconomicScenarios: PropTypes.func,
};

const mapStateToProps = (state) => ({
  calculatedEconomicScenarios: calcEconomicScenarios(state.ui) || [],
  isRelativeToBudget: state.ui.isRelativeToBudget || false,
  measureScale: state.ui.measureScale,
  printing: state.ui.printing,
  selectedStudy: state.ui.selectedStudy || {},
});

export default connect(mapStateToProps, {
  calcRiskReturn,
  dispatchIsRelativeToBudget,
  dispatchToExportStudy,
  dispatchCheckStudyValidity,
  storeAUMAndReturn,
  storeEconomicScenarios,
})(EconomicScenarios);
