import * as types from './types';
import { requestReducer, requestInitReducer } from '../../utils/Request';

export const DEFAULT_STATE = {
  ...requestInitReducer([types.TERMS_LATEST, types.TERMS_ALL, types.TERMS_SAVE]),
  accepted: undefined,
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.TERMS_ACCEPTED: {
      return { ...state, accepted: true };
    }
    case types.TERMS_MUST_ACCEPT: {
      return { ...state, accepted: false };
    }
    default:
      return requestReducer(state, [types.TERMS_LATEST, types.TERMS_ALL, types.TERMS_SAVE], action);
  }
};
