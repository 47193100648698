import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NotificationContainer } from 'react-notifications';

import Header from './layout/Header/Index';
import Content from './layout/Content/Content';
import Footer from './Footer';
import LoadingAnimation from './LoadingAnimation';
import * as colors from './StyleConstants';
import viewParams from './router/ViewParams';

const AppStyle = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    margin: 0px;
  }

  h3 {
    color: ${colors.blue1};
    padding: 0 0 0 24px;
  }

  h2 {
    color: ${colors.blue1};
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
  }

  h1 {
    color: white;
    padding: 0 0 0 24px;
    font-size: 120px;
  }
  @media screen and (max-width: 405px) {
    h1 {
      padding: 0px;
      font-size: 100px;
    }
  }
  @media screen and (max-width: 385px) {
    h4 {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 330px) {
    h1 {
      font-size: 90px;
    }
    h4 {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 320px) {
    h4 {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 310px) {
    h1 {
      font-size: 80px;
    }
    h4 {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 300px) {
    h4 {
      font-size: 14px;
    }
  }
`;

const Main = (props) => {
  const isInIframe = () => window.self !== window.top;
  return (
    <Fragment>
      <AppStyle>
        {!Boolean(isInIframe()) && <Header />}
        <Content id="content" selectedView={props.selectedView} viewParams={props.viewParams} />
        {!Boolean(isInIframe()) && <Footer />}
        <LoadingAnimation />
        <NotificationContainer />
      </AppStyle>
    </Fragment>
  );
};

Main.propTypes = {
  selectedView: PropTypes.string,
  viewParams,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Main);
