import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

import MaintenanceService from '../services/maintenanceService';
import * as types from './Types';
import { withLoading } from './CommonActions';
import { addEvent } from './EventActions';
import { Events } from '../services/Constants';
import { resetCredentials } from './UIActions';
import Constants from '../services/Constants';

export const saveMaintenance = withLoading((newStatus) => {
  return (dispatch, getState) => {
    const {
      ui: { okta },
    } = getState();
    const eventData = {
      oldStatus: !newStatus.enabled,
      newStatus: newStatus.enabled,
      userMail: okta.email,
    };
    dispatch(addEvent(Events.UPDATE_MAINTENANCE_MODE, eventData.newStatus));
    return MaintenanceService()
      .saveMaintenance(eventData)
      .then((result) => {
        if (result.data) {
          NotificationManager.success(
            'Maintenance mode enabled/disabled with success',
            'Maintenance Mode',
            Constants.POP_UP_TIME
          );
          // get the settings to know the maintenance mode state
          _.debounce(() => {
            if (okta.token) {
              return dispatch(resetCredentials());
            }
          }, Constants.POP_UP_TIME);
        } else {
          NotificationManager.error(
            'Error to enabled/disabled maintenance mode',
            'Maintenance Mode',
            Constants.POP_UP_TIME
          );
          // rollback last state
          dispatch(updateMaintenanceOption(!eventData.newStatus));
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to enabled/disabled maintenance mode',
          'Maintenance Mode',
          Constants.POP_UP_TIME
        );
        // rollback last state
        dispatch(updateMaintenanceOption(!enabled));
      });
  };
});

export const getImageUrl = () => {
  return MaintenanceService().getImageUrl();
};

export const updateMaintenanceImage = withLoading((newImage) => {
  return (dispatch, getState) => {
    return MaintenanceService()
      .updateMaintenanceImage(newImage)
      .then((result) => {
        if (result.data) {
          NotificationManager.success(
            'Maintenance image uploaded with success',
            'Maintenance Image',
            Constants.POP_UP_TIME
          );
        } else {
          NotificationManager.error(
            'Error to upload new image of maintenance mode',
            'Maintenance Image',
            Constants.POP_UP_TIME
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to update new image of maintenance mode',
          'Maintenance Image',
          Constants.POP_UP_TIME
        );
      });
  };
});

export const updateMaintenanceImageTmp = withLoading((newTempImage) => {
  return (dispatch, getState) => {
    return MaintenanceService()
      .updateMaintenanceImageTmp(newTempImage)
      .then((result) => {
        if (result.data) {
          NotificationManager.success(
            'Temp Maintenance image uploaded with success',
            'Temp Maintenance Image',
            Constants.POP_UP_TIME
          );
        } else {
          NotificationManager.error(
            'Error to upload new Temp image of maintenance mode',
            'Temp Maintenance Image',
            Constants.POP_UP_TIME
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to update new Temp image of maintenance mode',
          'Temp Maintenance Image',
          Constants.POP_UP_TIME
        );
      });
  };
});

export const resetMaintenanceTmpImage = () => {
  return (dispatch, getState) => {
    return MaintenanceService().resetMaintenanceImageTmp();
  };
};

export const updateMaintenanceOption = (selectedField) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SELECTED_MAINTENANCE_FIELD,
      maintenanceSelectedField: selectedField,
    });
  };
};

export const checkMaintenanceStatus = () => {
  return (dispatch, getState) => {
    return MaintenanceService()
      .checkIsUnderMaintence()
      .then((response) => {
        dispatch({ type: types.MAINTENANCE_STATUS, maintenanceStatus: response.data });
        return response.data === '' ? false : true;
      });
  };
};
