import styled from 'styled-components';

export const MainContainerRow = styled.div`
  display: flex;
  height: 520px;
  flex-direction: row;
  justify-content: center;
  background: #002060;
`;

export const MainContainerCol = styled.div`
  display: flex;
  margin-top: 4%;
  flex-direction: column;
`;

export const AuthWidget = styled.div`
  z-index: 2;
  #okta-sign-in {
    margin: 0px;
    h2 {
      color: #16709e;
      font-size: 1.5rem;
      font-weight: 700;
      font-family: montserrat, Arial, Helvetica, sans-serif;
      line-height: 1.5;
      margin-top: 10px;
    }
  }
`;

export const RegisterContainerHeader = styled.div`
  padding: 30px;
  border-bottom: 1px solid #ddd;
`;

export const RegisterContainerBody = styled.div`
  padding: 20px 42px;
`;

export const ImageContainer = styled.div`
  background-image: url('background.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  top: 0;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 520px;
  min-height: 520px;
  overflow-y: hidden;
`;

export const SkyanName = styled.div`
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: bold;
  z-index: 2;
`;

export const RegisterContainer = styled.div`
  z-index: 2;
  text-align: center;
  background-color: white;
  background-color: #fff;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 hsl(0deg 0% 69% / 12%);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  width: 400px;
  .nepc-captcha {
    margin-bottom: 8px;
    text-align: center;
    text-align: -webkit-center;
  }
  h2 {
    color: #16709e;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: montserrat, Arial, Helvetica, sans-serif;
    line-height: 1.5;
    margin-top: 10px;
  }
  .authContainer-btn {
    color: #fff;
    background-color: #16709e;
    border-color: #16709e;
    display: block;
    width: 100%;
    height: 3em;
    font-family: Open Sans, sans-serif !important;
    font-size: 1.125rem !important;
    font-weight: 700;
    border-radius: 0;
    margin: auto;
    line-height: 3em;
    background-image: none;
    &:disabled {
      color: #fff;
      background-color: #16709e;
      border-color: #16709e;
    }
  }
`;
