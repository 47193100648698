import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import ui from './UIReducer';
import okta from './OktaReducer';
import { Reducer as resources } from '../actions/Resources';
import terms from '../components/terms/reducer.js';

export const history = createBrowserHistory();

export default combineReducers({
  ui,
  resources,
  terms,
  okta,
  router: connectRouter(history),
});
