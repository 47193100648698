import styled from 'styled-components';

export const MaintenanceModeContainer = styled.div`
  display: flex;
  height: 520px;

  align-content: center;
  justify-content: center;
  background: #002060;
`;

export const ReasonArea = styled.div`
  position: relative;
  z-index: 99999999;
  top: 5rem;
`;

export const ReasonImage = styled.img`
  width: 50rem;
`;

export const BackgroundContainer = styled.div`
  background-image: url('/background.png');
  position: absolute;
  top: 0;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 520px;
  min-height: 520px;
  overflow-y: hidden;
`;
