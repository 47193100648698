import React from 'react';
import { NoAccessPage } from './styles';

const NoSkyanPage = (props) => {
  return (
    <NoAccessPage>
      Currently, you are not entitled to access Skyan application. Please contact your consultant.
    </NoAccessPage>
  );
};

export default NoSkyanPage;
