import styled from 'styled-components';

export const StyledCards = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1% 1%;

  .cards {
    margin-top: 15px;
    max-width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;

    .cardValue {
      text-align: right;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 135px;
  background: white;
  border: 1px solid #b6b6b6;
  border-radius: 8px;
  cursor: pointer;
  .bars-btn {
    -webkit-text-fill-color: #002060 !important;
    -webkit-opacity: 1;
  }
`;

export const CardTitle = styled.div`
  display: inline-flex;
  align-self: center;
  font-style: normal;
  font-weight: bold;
  color: #002060 !important;
  font-size: 16px;
  max-width: 210px;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  .p-splitbutton {
    background: transparent;
    .p-splitbutton-defaultbutton {
      display: none;
    }
    .p-splitbutton-menubutton {
      border: 0;
      background: transparent;
    }
  }
`;

export const TitleText = styled.div`
  color: #002060;
  text-align: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  text-align: center;
  width: 250px;
  margin: 0 0 3px 3px;
`;

export const CardText = styled.div`
  display: flex;
  justify-content: space-between;
  color: #b6b6b6;
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  div:not(.user-name) {
    display: flex;
    flex-direction: row;
  }
  .user-name {
    padding: 0 0 0 10px;
    width: 90px;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardsMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .p-dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    margin: 0px 9px;
    padding: 5px 0px 5px 10px;
    width: fill-available;
  }
  .nav-link {
    padding: 0;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    .p-dropdown {
      margin: 5px 0px;
    }
  }
`;

export const ActionButtons = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
`;
