import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';

import {
  resetMaintenanceTmpImage,
  updateMaintenanceImage,
  saveMaintenance,
  updateMaintenanceImageTmp,
  updateMaintenanceOption,
  getImageUrl,
  redirect,
} from '../../actions';

import Constants from '../../services/Constants';

import {
  HeaderText,
  Menu,
  MenuContent,
  MenuForm,
  MenuHeader,
  MaintenanceOptions,
  MaintenanceButtons,
  GroupForm,
  DisclaimerHeadertemplate,
} from './styles';
import ManagementButton from '../ManagementButton';

const SettingsPanel = (props) => {
  const {
    redirect,
    updateMaintenanceImageTmp,
    saveMaintenance,
    updateMaintenanceImage,
    resetMaintenanceTmpImage,
    updateMaintenanceOption,
    maintenanceStatus,
  } = props;

  const [maintenanceState, setMaintenanceState] = useState(maintenanceStatus !== '');
  const [selectedImage, setSelectedImage] = useState();
  const [tmpImagePath, setTmpImagePath] = useState();
  const [imgAux, setImgAux] = useState();
  const [onPreview, setOnPreview] = useState(false);

  const getMaintenanceURL = () => {
    if (Constants.PORT !== null) {
      return `https://${Constants.HOST}:${Constants.PORT}/settings`;
    }
    return `https://${Constants.HOST}/settings`;
  };

  const onChangeImageHandler = (event) => {
    const currentSelectedImage = event.target.files[0];
    setSelectedImage(currentSelectedImage);
    setImgAux(currentSelectedImage);
    updateMaintenanceImageTmp(currentSelectedImage);
  };

  const openPreview = () => {
    getImageUrl().then((resp) => {
      const tmpImagePath = selectedImage ? `/maintenance/tmpFile.png?${Math.random()}` : resp.data;
      setTmpImagePath(tmpImagePath);
      setOnPreview(true);
    });
  };

  const onSave = () => {
    resetMaintenanceTmpImage();
    const save = {
      enabled: maintenanceState,
    };
    if (imgAux) {
      updateMaintenanceImage(imgAux);
    }
    setSelectedImage(null);
    saveMaintenance(save);
  };

  return (
    <Menu>
      <Dialog
        draggable={false}
        resizable={false}
        visible={onPreview}
        onMaskClick={() => setOnPreview(false)}
        style={{ width: '80vw', height: '100vh' }}
        contentStyle={{
          background: 'transparent',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          className="reasonImagePreview"
          src={tmpImagePath}
          alt="Under maintenance"
          style={{
            position: 'absolute',
            maxHeight: '100vh',
            maxWidth: '1200px',
          }}
        />
      </Dialog>
      <MenuContent>
        <MenuHeader>
          <HeaderText>Settings</HeaderText>
          <ManagementButton action={() => redirect('/')} backIcon="pi pi-times" circle={true} border={false} />
        </MenuHeader>
        <MenuForm>
          <GroupForm>
            <HeaderText>Maintenance Mode</HeaderText>
            <Panel headerTemplate={DisclaimerHeadertemplate} toggleable collapsed={true}>
              <span>
                Once maintenance mode is enabled, you will be forced out of the website and it will be unavailable for
                all users and can only be access by admin users throught the url
              </span>
              <ul>
                <li>
                  <a href={getMaintenanceURL()}>{getMaintenanceURL()}</a>
                </li>
              </ul>
              <span className="ps-disclaimer">
                ps: we do recommend to bookmark the above url, before enabling the maintenance mode
              </span>
            </Panel>
            <MaintenanceOptions>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="maintenance-true"
                  name="maintenanceStatetrue"
                  value={true}
                  onChange={(e) => {
                    setMaintenanceState(e.value);
                    updateMaintenanceOption(e.value);
                  }}
                  checked={maintenanceState}
                />
                <label htmlFor="maintenance-true">Enable maintenance mode</label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="maintenance-false"
                  name="maintenanceStatefalse"
                  value={false}
                  onChange={(e) => {
                    setMaintenanceState(e.value);
                    updateMaintenanceOption(e.value);
                  }}
                  checked={!maintenanceState}
                />
                <label htmlFor="maintenance-false">Disable maintenance mode</label>
              </div>
            </MaintenanceOptions>
            <p>Select a new maintenance image to update the current one.</p>
            <input type="file" id="imgInput" accept="image/*" onChange={onChangeImageHandler} />
            <MaintenanceButtons>
              <ManagementButton
                action={openPreview}
                className="previewButton"
                btnText="Preview"
                id="preview-button"
                border
              />
              <ManagementButton action={onSave} backIcon="pi-save" btnText="Save" id="save-button" border />
            </MaintenanceButtons>
          </GroupForm>
        </MenuForm>
      </MenuContent>
    </Menu>
  );
};

SettingsPanel.propTypes = {
  resetMaintenanceTmpImage: PropTypes.func,
  updateMaintenanceImage: PropTypes.func,
  saveMaintenance: PropTypes.func,
  updateMaintenanceImageTmp: PropTypes.func,
  updateMaintenanceOption: PropTypes.func,
  maintenanceStatus: PropTypes.string,
  redirect: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    maintenanceStatus: state.resources.maintenance_status.data,
  };
};

export default connect(mapStateToProps, {
  updateMaintenanceImageTmp,
  resetMaintenanceTmpImage,
  updateMaintenanceImage,
  saveMaintenance,
  updateMaintenanceOption,
  redirect,
})(SettingsPanel);
