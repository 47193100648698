import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

import { Button, UncontrolledTooltip } from 'reactstrap';

import { StyledAssetsPools } from './StyledAssetPools';
import DefaultBox from '../DefaultBox';
import {
  dispatchAssetPoolsData,
  dispatchCleanUpCalcValues,
  dispatchToExportStudy,
  dispatchTotalMarketValue,
  dispatchEmptyAssetPoolDescription,
  dispatchEmptyAssetPoolRiskProxy,
  loadAssetAllocation,
} from '../../actions';
import { NotificationManager } from 'react-notifications';
import ManagementButton from '../ManagementButton';

const AssetPools = (props) => {
  const { riskProxies, selectedAllocatedByValue, selectedStudy, totalMarketValue } = props;

  const { decimalPrecision } = selectedStudy.study;

  const fillUpMixes = (_assetPoolsData) =>
    _assetPoolsData.map((item) => {
      let mixItem = { mixName: '', mixId: 0, mixGuid: 0 };
      mixItem = riskProxies.find((risk) => {
        return item.riskProxyId === risk.integrationId;
      });
      if (!mixItem) {
        NotificationManager.warning(`Please select a risk proxy to "${item.description}" pool`);
      }
      return { ...item, ...mixItem };
    });

  const [daysCashOnHand, setDaysCashOnHand] = useState([]);
  const [totalDCOH, setTotalDCOH] = useState(0);
  const [mustCleanUpCalcValues, setMustCleanUpCalcValues] = useState(false);

  useEffect(() => {
    if (
      riskProxies.length &&
      selectedStudy.assetPools.length &&
      selectedStudy.assetPools.every((asset) => asset.riskProxyId && !asset.mixId)
    ) {
      props.dispatchAssetPoolsData(fillUpMixes(selectedStudy.assetPools));
    }
  });

  useEffect(() => {
    if (riskProxies.length && selectedAllocatedByValue) {
      props.loadAssetAllocation(
        riskProxies.map((riskProxy) => riskProxy.mixId),
        selectedAllocatedByValue
      );
      props.dispatchAssetPoolsData(fillUpMixes(selectedStudy.assetPools));
    }
  }, [riskProxies, selectedAllocatedByValue]);

  useEffect(() => {
    setDaysCashOnHand(
      selectedStudy.assetPools.map((item) =>
        Math.round(item.aum / selectedStudy.financialMetrics.dailyExpenseFactor.year0)
      )
    );
  }, [selectedStudy.financialMetrics.dailyExpenseFactor.year0]);

  useEffect(() => {
    if (mustCleanUpCalcValues) {
      resetCalcValues();
    }
  }, [mustCleanUpCalcValues]);

  useEffect(() => {
    props.dispatchEmptyAssetPoolRiskProxy(selectedStudy.assetPools.some((pool) => !pool.riskProxyId));
    props.dispatchEmptyAssetPoolDescription(selectedStudy.assetPools.some((pool) => !pool.description));
    props.dispatchTotalMarketValue(_.sumBy(selectedStudy.assetPools, 'aum'));
  }, [selectedStudy.assetPools]);

  useEffect(() => {
    setTotalDCOH(daysCashOnHand.reduce((value, acc) => value + acc, 0));
  }, [daysCashOnHand]);

  useEffect(() => {
    props.dispatchToExportStudy({
      assetPools: [
        ...selectedStudy.assetPools.map((asset, idx) => ({
          ...asset,
          daysCashOnHand: daysCashOnHand[idx],
        })),
      ],
      totalDCOH,
    });
  }, [daysCashOnHand, selectedStudy.assetPools, totalDCOH]);

  const resetCalcValues = () => {
    props.dispatchCleanUpCalcValues();
    setMustCleanUpCalcValues(false);
  };

  const handleRiskChange = (rowData, value) => {
    const changedAssetPools = selectedStudy.assetPools;
    changedAssetPools[rowData.rowIndex] = {
      ...selectedStudy.assetPools[rowData.rowIndex],
      riskProxyId: value.integrationId,
      ...value,
    };
    props.dispatchAssetPoolsData(changedAssetPools);
    setMustCleanUpCalcValues(true);
  };

  const handleMarketValueChange = ({ rowIndex }, value) => {
    const changedAssetPools = selectedStudy.assetPools;
    changedAssetPools[rowIndex] = { ...changedAssetPools[rowIndex], aum: value || 1 };
    setDaysCashOnHand(
      daysCashOnHand.map((item, idx) =>
        idx === rowIndex ? Math.round(value / selectedStudy.financialMetrics.dailyExpenseFactor.year0) : item
      )
    );
    props.dispatchAssetPoolsData(changedAssetPools);
    resetCalcValues();
  };

  const handleDescriptionChange = (rowData, value) => {
    if (value.length <= 25) {
      const changedAssetPools = [...selectedStudy.assetPools];
      changedAssetPools[rowData.rowIndex] = { ...selectedStudy.assetPools[rowData.rowIndex], description: value };
      props.dispatchAssetPoolsData(changedAssetPools);
    }
  };

  const renderDescriptionBody = (rowData, colBodyOptions) => {
    const isTouchScreen = !('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    return (
      <div className="pool-description">
        <ManagementButton
          backIcon="pi-trash"
          className="noPrint"
          action={() => removeRow(rowData)}
          circle
          showOnHover={isTouchScreen}
        />
        <InputText
          value={rowData['description']}
          className="table-editor noPrint"
          onChange={(event) => handleDescriptionChange(colBodyOptions, event.target.value)}
          onBlur={(event) => handleDescriptionBlur(event.target.value)}
        ></InputText>
        <div className="printOnly">{rowData['description']}</div>
      </div>
    );
  };

  const handleDescriptionBlur = (value) => {
    !value && NotificationManager.error(`Please enter a description for the new pool`);
  };

  const renderMarketValueBody = (rowData, colBodyOptions) => (
    <>
      <InputNumber
        value={rowData.aum}
        className="aum-input noPrint"
        onChange={(inputParams) => handleMarketValueChange(colBodyOptions, inputParams.value)}
        min={1}
        max={9999999999999999} // max value is to limit the character length
        allowEmpty={false}
        mode="decimal"
        locale="en-US"
        minFractionDigits={decimalPrecision}
        maxFractionDigits={decimalPrecision}
        onFocus={(event) => {
          event.target.selectionStart = 0;
          event.target.selectionEnd = event.target.value.length;
        }}
      />
      <div className="printOnly">{rowData.aum}</div>
    </>
  );

  const renderRiskProxy = (rowData, colBodyOptions) => {
    const riskProxy = riskProxies.find((row) => row.integrationId === rowData['riskProxyId']);
    return (
      <Dropdown
        value={riskProxy}
        options={riskProxies}
        optionLabel="mixName"
        id="risk-proxy-selection"
        onChange={(event) => handleRiskChange(colBodyOptions, event.value)}
        placeholder="Select a Risk proxy"
        itemTemplate={(option) => (
          <span className="product-badge" id={`${option.mixName}-dropdown-${colBodyOptions.rowIndex}`}>
            {option.mixName}
          </span>
        )}
      />
    );
  };

  const prettyPercent = (rateValue) => (
    <span>
      {isNaN(rateValue) || rateValue === null
        ? '---'
        : ((rateValue || 0) * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
          '%'}
    </span>
  );

  const addNewRow = () => {
    let newId = _.random(100000000, false);
    while (_.includes(selectedStudy.assetPools, newId)) {
      newId = _.random(100000000, false);
    }
    const newAssetPools = [
      ...selectedStudy.assetPools,
      {
        studyId: selectedStudy.study.studyId,
        description: 'New Pool',
        aum: 1,
        riskProxyId: 0,
        assetPoolId: newId,
      },
    ];
    props.dispatchAssetPoolsData(newAssetPools);
    setDaysCashOnHand([...daysCashOnHand, 1 / selectedStudy.financialMetrics.dailyExpenseFactor.year0]);
    setMustCleanUpCalcValues(true);
  };

  const removeRow = (row) => {
    let deletedIndex;
    const remainingItems = selectedStudy.assetPools.filter((assetPool) => {
      if (assetPool.assetPoolId !== row.assetPoolId) {
        return assetPool.assetPoolId !== row.assetPoolId;
      }
      deletedIndex = selectedStudy.assetPools.indexOf(row);
    });
    const newCashOnHand = daysCashOnHand.filter((day, i) => {
      return i !== deletedIndex;
    });

    props.dispatchAssetPoolsData(remainingItems);
    setDaysCashOnHand(newCashOnHand);
    setMustCleanUpCalcValues(true);
    NotificationManager.success(`Row deleted successfully`);
  };

  const handleRowReorder = (event) => {
    const orderedAssetPools = event.value;
    props.dispatchAssetPoolsData(orderedAssetPools);
    setDaysCashOnHand(
      orderedAssetPools.map((item) => Math.round(item.aum / selectedStudy.financialMetrics.dailyExpenseFactor.year0))
    );
  };

  const getDaysCashOnHandHeader = () => (
    <div className="with-info">
      <div className="description">Days Cash on Hand</div>
      <div className="info">
        <Button color="link" className="link-info">
          <i id="days-cash-on-hand-info" className="pi pi-info-circle"></i>
        </Button>
        <UncontrolledTooltip placement="top" trigger="hover" target="days-cash-on-hand-info">
          Year 0
        </UncontrolledTooltip>
      </div>
    </div>
  );

  return (
    <DefaultBox className="poolDesc">
      <StyledAssetsPools>
        <div className="asset-pool-header">
          <h2>Unrestricted Cash & Investments</h2>
          <ManagementButton action={() => addNewRow()} btnText="Add Row" id="add-row-assetpools-button" border />
        </div>

        {Boolean(selectedStudy.assetPools) && Boolean(selectedStudy.study) && Boolean(riskProxies) && (
          <div className="asset-pool-table">
            <DataTable
              className="asset-pools-table"
              value={selectedStudy.assetPools}
              responsiveLayout="scroll"
              onRowReorder={handleRowReorder}
              reorderableRows
              showGridlines
            >
              {!Boolean('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) && (
                <Column style={{ width: '2em' }} className="noPrint reorder-button" rowReorder></Column>
              )}
              <Column
                body={(rowData, colBodyOptions) => renderDescriptionBody(rowData, colBodyOptions)}
                footer="Total"
                footerClassName="description-footer"
                header="Components"
                field="description"
                className="asset-pools-description"
                bodyClassName="description-body"
                headerClassName="description-header"
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => renderMarketValueBody(rowData, colBodyOptions)}
                footer={totalMarketValue.toLocaleString('en-US', {
                  minimumFractionDigits: decimalPrecision,
                  maximumFractionDigits: decimalPrecision,
                })}
                bodyClassName="aum-value-body"
                footerClassName="aum-footer"
                header={`Market Value ($${props.measureScale.measureSymbol})`}
                headerClassName="aum-header"
              ></Column>
              <Column
                body={(rowData, { rowIndex }) =>
                  daysCashOnHand[rowIndex]?.toLocaleString('en-US', { maximumFractionDigits: 0 })
                }
                bodyClassName="days-cash-on-hand-body"
                footer={totalDCOH?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                footerClassName="days-cash-on-hand-footer"
                header={getDaysCashOnHandHeader()}
                headerClassName="days-cash-on-hand-header"
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => renderRiskProxy(rowData, colBodyOptions)}
                footer="---"
                footerClassName="risk-proxy-footer"
                header="Asset Allocation Proxy"
                bodyClassName="risk-proxy-body"
                headerClassName="risk-proxy-header"
                className="risk-proxy-column"
              ></Column>
              <Column
                body={(rowData) => prettyPercent(rowData.expRtnGeo10Yrs)}
                bodyClassName="ten-year-exp-rtn-body"
                footer={prettyPercent(selectedStudy.study.expRtnGeo10Yrs)}
                footerClassName="ten-year-exp-rtn-footer"
                header="10 Year Expected Return"
                headerClassName="ten-year-exp-rtn-header"
              ></Column>
              <Column
                body={(rowData) => prettyPercent(rowData.standardDev)}
                bodyClassName="standard-dev-body"
                footer={prettyPercent(selectedStudy.study.standardDev)}
                footerClassName="standard-dev-footer"
                header="Standard Deviation"
                headerClassName="standard-dev-header"
              ></Column>
            </DataTable>
          </div>
        )}
      </StyledAssetsPools>
    </DefaultBox>
  );
};

AssetPools.propTypes = {
  dispatchAssetPoolsData: PropTypes.func,
  dispatchCleanUpCalcValues: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  dispatchTotalMarketValue: PropTypes.func,
  dispatchEmptyAssetPoolDescription: PropTypes.func,
  dispatchEmptyAssetPoolRiskProxy: PropTypes.func,
  loadAssetAllocation: PropTypes.func,
  measureScale: PropTypes.object,
  riskProxies: PropTypes.array,
  selectedAllocatedByValue: PropTypes.string,
  selectedStudy: PropTypes.object,
  totalMarketValue: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    measureScale: state.ui.measureScale,
    riskProxies: state.resources.risk_proxies.data || [],
    selectedAllocatedByValue: state.ui.selectedAllocatedByValue || '',
    selectedStudy: state.ui.selectedStudy || {},
    totalMarketValue: state.ui.totalMarketValue || 0,
  };
};

export default connect(mapStateToProps, {
  dispatchAssetPoolsData,
  dispatchCleanUpCalcValues,
  dispatchToExportStudy,
  dispatchTotalMarketValue,
  dispatchEmptyAssetPoolDescription,
  dispatchEmptyAssetPoolRiskProxy,
  loadAssetAllocation,
})(AssetPools);
