/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

const Loading = styled.div`
  .loaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
  }

  .spinnerContainer {
    position: absolute;
    top: 40%;
    left: calc(50% - 2rem);
    text-align: center;
    font-size: 10px;
  }

  .spinnerIcon {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border: 8px solid #16709e;
    border-right-color: transparent;
    animation: 1.5s linear infinite spinner-border;
  }
`;

export class LoadingAnimation extends Component {
  render() {
    if (this.props.isLoading) {
      return (
        <Loading>
          <div className="loaderWrapper">
            <div className="spinnerContainer">
              <Spinner className="spinnerIcon" children="" />
            </div>
          </div>
        </Loading>
      );
    }
    return null;
  }
}

LoadingAnimation.propTypes = {
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
  };
};

export default connect(mapStateToProps, null)(LoadingAnimation);
